/* eslint-disable @typescript-eslint/no-explicit-any */
import Heading from "@/components/customUI/Heading"
import Blog from "./Blog"
import PopularArticle from "./PopularArticle"
import { useCallback, useEffect, useState } from "react"
import { IBlog } from "@/types"
import request from "@/utils/request"
import { PaginationComp } from "@/components/customUI/PaginationComp"
import PopularLoading from "./PopularLoading"
import BlogLoading from "./BlogLoading"
import { SearchX } from "lucide-react"
import { Helmet } from "react-helmet-async"
import { company_name, DOMAIN_URL } from "@/config"

const BlogPage = () => {

    const [blogs, setBlogs] = useState<IBlog[]>([])
    const [populars, setPopuplars] = useState<IBlog[]>([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [popLoading, setPopLoading] = useState(true)

    const handleGetBlogs = useCallback(async() => {
        try {
            setLoading(true)
            const res = await request({url: `/blogs/getBlogs?page=${page}`, method: 'get'})
            setBlogs(res.data.data)
            setTotal(res.data.total)
            setTotalPages(res.data.totalPages)
        } catch (error: any) {
            throw new Error(error);
        } finally {
            setLoading(false)
        }
    }, [page])

    useEffect(() => {
        handleGetBlogs()
    }, [handleGetBlogs])
    
    const handleGetPopuplars = useCallback(async() => {
        try {
            setPopLoading(true)
            const res = await request({url: '/blogs/popular', method: 'get'})
            setPopuplars(res.data)
        } catch (error: any) {
            throw new Error(error)
        } finally {
            setPopLoading(false)
        }
    }, [])

    useEffect(() => {
        handleGetPopuplars()
    }, [handleGetPopuplars])
    

  return (
    <>
    <Helmet>
      <title>Emlak Haberleri | {company_name}</title>
      <meta name="description" content={`${company_name}'den en güncel emlak haberleri, piyasa analizleri ve emlak sektöründeki gelişmeleri takip edin. Sakarya'daki emlak fırsatlarını kaçırmayın!`} />
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={`Emlak Haberleri, ${company_name}, Sakarya emlak, emlak piyasası, kiralık daire, satılık daire, arsa haberleri, gayrimenkul`} />
      <meta property="og:title" content={`Emlak Haberleri | ${company_name}`} />
      <meta property="og:description" content={`${company_name}'den en güncel emlak haberleri, piyasa analizleri ve emlak sektöründeki gelişmeleri takip edin. Sakarya'daki emlak fırsatlarını kaçırmayın!`} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`${DOMAIN_URL}/assets/icon/logo.svg`} />
      <meta property="og:site_name" content={company_name} />
    </Helmet>
    <main
    className="container-wrapper pb-5 lg:pb-10 space-y-5">
        <div className="w-full p-5 flex items-center justify-between text-white bg-first">
            <h1 className="lg:text-xl font-semibold">Haberler</h1>
            <p className="text-sm"><b>{total}</b> içerik bulundu.</p>
        </div>

        <div className="grid grid-cols-4 gap-5">
            <div className="col-span-4 lg:col-span-3 space-y-5">
                <Heading title="Sonuçlar"/>
                {
                    !loading ?
                    blogs.length > 0 ?
                    blogs.map((v, i) => (
                        <Blog key={i} data={v}/>
                    ))
                    :
                    <div className="flex items-center justify-center flex-col gap-3 w-full h-[300px] lg:h-[550px] text-lg font-semibold text-[#A9B0B9]">
                        <SearchX size={30}/>
                        <p>İçerik bulunamadı...</p>
                    </div>
                    : 
                    [...Array(5)].map((_, i) => (
                        <BlogLoading key={i}/>
                    ))
                }
                {
                    totalPages > 0 && 
                    (
                        <PaginationComp
                            currentPage={page}
                            setPage={setPage}
                            totalPages={totalPages}
                        />
                    )
                }
            </div>
            <div className="col-span-4 lg:col-span-1 space-y-5">
                <Heading title="Popüler İçerikler"/>
                {   
                !popLoading ?
                    populars.map((v, i) => (
                        <PopularArticle key={i} data={v}/>
                    ))
                    :
                    [...Array(5)].map((_, i) => (
                        <PopularLoading key={i}/>
                    ))
                }
            </div>
        </div>
    </main>
    </>
  )
}

export default BlogPage