import { cn } from "@/lib/utils"
import { useState } from "react"
import Description from "./Description"
import Map from "./Map"
import Info from "./Info"
import Properties from "./Properties"
import { IListing } from "@/types"

const Tabs = ({
    data
}: {
    data: IListing
}) => {

    const [tab, setTab] = useState(0)

    const tabs = [
        'İlan Bilgileri',
        'İlan Açıklaması',
        'İlan Konumu',
        'İlan Özellikleri',
    ]

  return (
    <section className="col-span-12 my-5 lg:col-span-9 border border-border rounded-xl overflow-hidden">
        <div className="w-full grid grid-cols-2 lg:grid-cols-4 border-b border-border overflow-hidden">
            {
                tabs.map((v, i) => (
                    <button 
                    key={i} 
                    onClick={() => setTab(i)}
                    className={cn('px-5 w-full text-xs lg:px-10 h-10 shrink-0 lg:text-sm font-semibold duration-300 border-r ', 
                    tab === i ? 'text-primary bg-first text-white border-b-first border-r-first' : 'text-primary border-r-border border-b-border',
                    i < 2 && 'max-lg:border-b')}>
                        {v}
                    </button>
                ))
            }
        </div>
        {tab === 0 && (<Info data={data!}/>)}
        {tab === 1 && (<Description desc={data.desc}/>)}
        {tab === 2 && (<Map link={data.map_link}/>)}
        {tab === 3 && (<Properties props={data.features}/>)}
    </section>
  )
}

export default Tabs