import Image from "@/components/ui/image";
import { API_URL } from "@/config";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Slider from "react-slick";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import Counter from "yet-another-react-lightbox/plugins/counter";

interface ArrowButtonProps {
    onClick: () => void;
}

const Images = ({
    images,
}: {
    images: string[];
}) => {
    const [selectedImage, setSelectedImage] = useState(0)
    const [openLightbox, setOpenLightbox] = useState(false);

    const PrevBtn : React.FC<ArrowButtonProps> = ({onClick}) => {
    return (
        <button 
            onClick={onClick} 
            className="absolute top-0 !left-0 h-full w-[35px] shrink-0 text-white bg-first hover:bg-primary hover:text-first duration-300 text-primary flex items-center justify-center">
            <BiChevronLeft size={28}/>
            </button>
    );
    };
    const NextBtn: React.FC<ArrowButtonProps> = ({onClick}) => {
    return (
        <button 
        onClick={onClick} 
        className="absolute top-0 !right-0 h-full w-[35px] shrink-0 text-white bg-first hover:bg-primary hover:text-first duration-300 text-primary flex items-center justify-center">
            <BiChevronRight size={28}/>
        </button>
    );
    };

    const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    className: 'slider-size animate-fade slider-padding',
    nextArrow: <NextBtn onClick={() => {}}/>,
    prevArrow: <PrevBtn onClick={() =>  {}}/>,
    responsive: [
        {
        breakpoint: 1024,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
        }
        },
        {
        breakpoint: 600,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
        }
        }
    ]
    };
  return (
    <div className="space-y-5">
            <div 
                className="w-full aspect-[940/510] overflow-hidden border border-border rounded-xl cursor-pointer"
                onClick={() => setOpenLightbox(true)} // Lightbox'u aç
            >
                <Image
                    className="w-full h-full object-contain"
                    src={`${API_URL}/${images[selectedImage]}`}
                />
            </div>
        {images && images?.length > 2 ? 
        <div className="w-full aspect-[5/1] lg:aspect-[7/1] overflow-hidden">
        <Slider {...settings}>
            {
            images?.map((v, i) => (
                <div key={i} 
                onClick={() => setSelectedImage(i)}
                className={cn(`px-2 flex h-full duration-300 cursor-pointer rounded-xl overflow-hidden border`, 
                i === selectedImage ? 'border-first opacity-100' : 'border-transparent opacity-80')}>
                <Image
                    src={`${API_URL}/${v}`}
                />
                </div>
            ))
            }
        </Slider>
        </div> : null}

        <Lightbox
                open={openLightbox}
                close={() => setOpenLightbox(false)} // Lightbox'u kapat
                slides={images.map((img) => ({ src: `${API_URL}/${img}` }))}
                plugins={[Counter]} // Sayac eklentisi
                index={selectedImage} // Seçili resim
                styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
            />
    </div>
  )
}

export default Images