/* eslint-disable @typescript-eslint/no-explicit-any */
import Heading from "@/components/customUI/Heading"
import PopularArticle from "../PopularArticle"
import Share from "./Share"
import { useCallback, useEffect, useState } from "react"
import request from "@/utils/request"
import { IBlog } from "@/types"
import PopularLoading from "../PopularLoading"
import { useNavigate, useParams } from "react-router-dom"
import Image from "@/components/ui/image"
import { API_URL, DOMAIN_URL } from "@/config"
import LoadingScreen from "@/components/customUI/LoadingScreen"
import { Helmet } from "react-helmet-async"
import { seoDesc } from "@/utils/helpers"

const DetailPage = () => {

    const { seo } = useParams<{ seo: string }>();

    const [data, setData] = useState<IBlog>()
    const navigate = useNavigate()
    const [populars, setPopuplars] = useState<IBlog[]>([])
    const [popLoading, setPopLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const handleGetPopuplars = useCallback(async() => {
            try {
                setPopLoading(true)
                const res = await request({url: '/blogs/popular', method: 'get'})
                setPopuplars(res.data)
            } catch (error: any) {
                throw new Error(error)
            } finally {
                setPopLoading(false)
            }
        }, [])

        useEffect(() => {
            handleGetPopuplars()
        }, [handleGetPopuplars])

        const handleGetData = useCallback(async() => {
            try {
                setLoading(true);
                const res = await request({url: `/blogs/getBySeo?seo=${seo}`, method: 'get'})
                setData(res.data)
            } catch (err: any) {
                if (err.response && err.response.status === 404) {
                    navigate('/404');
                    } else {
                    throw new Error(err);
                    }
            } finally {
                setLoading(false)
            }
        }, [navigate, seo])

        useEffect(() => {
            handleGetData()
        }, [handleGetData])
        

        if(loading) {
            return <LoadingScreen/>
        }

  return (
    <>
    <Helmet>
    <title>{data?.title} | Gündoğdu Emlak Blog</title>
    <meta name="description" content={seoDesc(data?.desc || '')} />
    <meta name="robots" content="index, follow" />
    <meta name="keywords" content="blog, Gündoğdu Emlak, Sakarya emlak, emlak haberleri, kiralık daire, satılık daire" />
    <meta property="og:title" content={`${data?.title} | Gündoğdu Emlak`} />
    <meta property="og:description" content={seoDesc(data?.desc || '')} />
    <meta property="og:url" content={`${DOMAIN_URL}/haberler/${data?.seo}`} />
    <meta property="og:image" content={`${API_URL}/${data?.image}`} />
    <meta property="og:type" content="article" />
    <meta property="og:site_name" content="Gündoğdu Emlak" />
    </Helmet>
    <main className="space-y-5 py-5 lg:py-10 grid grid-cols-4 gap-8 container-wrapper">
        <section className="col-span-4 lg:col-span-3 space-y-5">
            <h1 className="text-lg lg:text-xl font-semibold">
                {data?.title}
            </h1>

            <div className="w-full aspect-[1/0.35] rounded-xl overflow-hidden">
                <Image src={`${API_URL}/${data?.image}`} alt={data?.title}/>
            </div>

            {data &&
            (<Share
                data={data!}
            />)
            }

            <p 
            dangerouslySetInnerHTML={{__html: data?.desc || ''}}
            className="text-xs lg:text-sm font-medium text-third-dark p-3"/>
        </section>
        <aside className="col-span-4 lg:col-span-1 space-y-3">
            <Heading title="Popüler İçerikler"/>
            {   
                !popLoading ?
                    populars.map((v, i) => (
                        <PopularArticle key={i} data={v}/>
                    ))
                    :
                    [...Array(5)].map((_, i) => (
                        <PopularLoading key={i}/>
                    ))
                }
        </aside>
    </main>
    </>
  )
}

export default DetailPage