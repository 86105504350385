import { IBlog } from "@/types"
import { onlyDate, share } from "@/utils/helpers"
import { Calendar, EyeIcon, Facebook, Share2Icon, Twitter } from "lucide-react"
import { FaWhatsapp } from "react-icons/fa"

const Share = ({
    data
}: {
    data: IBlog
}) => {

  return (
    <div className="flex items-center justify-between gap-5 max-lg:flex-col text-sm text-third-dark font-semibold">
        <div className="flex items-center gap-5">
            <div className="flex items-center gap-2">
                <Calendar size={16}/>
                <span>{onlyDate(new Date(data.createdAt))}</span>
            </div>
            <div className="flex items-center gap-2">
                <EyeIcon size={16}/>
                <span>Görüntülenme: {data.readCount}</span>
            </div>
        </div>

        <div className="flex items-center gap-5 flex-wrap">
            <div className="flex items-center gap-2">
                <Share2Icon size={16}/>
                <span>Paylaş</span>
            </div>

            <button onClick={() => share({_title: data.title, platform: 'whatsapp', previewImage: data.image})}>
                <FaWhatsapp size={16}/>
            </button>
            <button onClick={() => share({_title: data.title, platform: 'facebook', previewImage: data.image})}>
                <Facebook size={16}/>
            </button>
            <button onClick={() => share({_title: data.title, platform: 'twitter', previewImage: data.image})}>
                <Twitter size={16}/>
            </button>
        </div>
    </div>
  )
}

export default Share