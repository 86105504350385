/* eslint-disable @typescript-eslint/no-explicit-any */
import request from "@/utils/request"
import Articles from "./Articles"
import Featured from "./Featured"
import Hero from "./Hero"
import { useCallback, useEffect, useState } from "react"
import { IBlog, IListing } from "@/types"
import { Helmet } from "react-helmet-async"
import { company_name, DOMAIN_URL } from "@/config"
import { useCategories } from "@/contexts/CategoriesProvider"
import CategoryComp from "./Categories"

const Home = () => {

  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const [highlights, setHighlights] = useState<IListing[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleGetBlogs = useCallback(async () => {
    const res = await request({ url: `/blogs/getBlogs?page=1`, method: "get" });
    return res.data.data;
  }, []);

  const handleGetHighlights = useCallback(async () => {
    const res = await request({ url: `/listing/highlights?page=1`, method: "get" });
    return res.data.data;
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);
        const [blogsData, highlightsData] = await Promise.all([
          handleGetBlogs(),
          handleGetHighlights()
        ]);
        setHighlights(highlightsData)
        setBlogs(blogsData);
      } catch (error: any) {
        throw new Error(error)
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [handleGetBlogs, handleGetHighlights]);

  const {categories} = useCategories()  

  return (
    <>
      <Helmet>
        <title>Sakarya Emlak İlanları | {company_name}</title>
        <meta name="description" content={`${company_name} ile Sakarya'da satılık ve kiralık daireler, arsa, ticari gayrimenkuller. En güncel emlak ilanları için hemen ziyaret edin.`} />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={`Sakarya emlak, ${company_name}, satılık daire Sakarya, kiralık daire Sakarya, arsa Sakarya, gayrimenkul Sakarya, emlak ilanları`} />
        <meta name="author" content="Emircan Yaşar" />
        <meta property="og:title" content={`Sakarya'da Satılık ve Kiralık Emlak İlanları | ${company_name}`} />
        <meta property="og:description" content={`Sakarya'da en güncel satılık ve kiralık daire, arsa, ve ticari gayrimenkul ilanları ${company_name}'ta. Size uygun fırsatları kaçırmayın!`} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={company_name} />
        <meta property="og:locale" content="tr_TR" />
        <meta property="og:image" content={`${DOMAIN_URL}/assets/icon/logo.svg`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Sakarya'da Satılık ve Kiralık Emlak İlanları | ${company_name}`} />
        <meta name="twitter:description" content={`Sakarya'da en güncel satılık ve kiralık daire, arsa, ve ticari gayrimenkul ilanları ${company_name}'ta. Fırsatları kaçırmayın!`} />
      </Helmet>
      <main className="space-y-10 lg:space-y-20 pb-10 lg:pb-20">
        <Hero/>
        <Featured 
          highlights={highlights}
          loading={loading}
        />
        {categories?.map((v, i) => (
          <CategoryComp
            key={i}
            category={v}
          />
        ))}
        <Articles
          blogs={blogs}
          loading={loading}
        />
      </main>
    </>
  )
}

export default Home