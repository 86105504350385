import SelectComp from '@/components/customUI/SelectComp'
import Modal from '@/components/modals/Modal'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useCategories } from '@/contexts/CategoriesProvider'
import { cn } from '@/lib/utils'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { District, Neighborhoods } from '../CategoryPage'
import request from '@/utils/request'
import SelectCompForAdres from '@/components/customUI/SelectCompForAdres'

const SearchModal = ({
    open,
    onClose,
}: {
    open: boolean
    onClose: () => void
}) => {

    const [index, setIndex] = useState(0)
    const navigate = useNavigate()
    const [type, setType] = useState('')
    const [text, setText] = useState('')
    const {categories} = useCategories()
    const [district, setDistrict] = useState('')
    const [neighborhood, setNeighborhood] = useState('')
    const [districts, setDistricts] = useState<District[]>([])
    const [neighborhoods, setNeighborhoods] = useState<Neighborhoods[]>([])
    const [dateValue, setDateValue] = useState('')
    
    const handleSearch = () => {
        const queryParams = new URLSearchParams();

        if (type) {
            queryParams.append('category', type);
        }

        if (text) {
            queryParams.append('query_text', text);
        }
        if (district) {
            queryParams.append('district_id', district);
        }
        if (neighborhood) {
            queryParams.append('neighborhood_id', neighborhood);
        }
        if (dateValue) {
            queryParams.append('date', dateValue);
        }

        const url = `/${categories[index].seo}?${queryParams.toString()}&query_desc=true`;

        navigate(url);
    };

    useEffect(() => {
        setType('')
    }, [index])
    

    const getDistricts = useCallback(async () => {
            try {
                const response = await request({url: `/address/districts?city_id=${54}`, method: 'get'});
                setDistricts(response.data);
                } catch (err) {
                console.error(err);
                }
        }, []);

    const getNeighborhoods = useCallback(async () => {
    if(district) {
        try {
            const response = await request({url: `/address/neighborhoods?district_id=${district}`, method: 'get'});
            setNeighborhoods(response.data);
            } catch (err) {
            console.error(err);
            }
    }
    }, [district]);

    useEffect(() => {
        if(open) {
            getDistricts()
        }
    }, [getDistricts, open])

    useEffect(() => {
        if(open) {
            getNeighborhoods()
        }
    }, [getNeighborhoods, open])

    const dateArr = [
        {name: 'Son 24 saat', value: '24h'},
        {name: 'Son 3 gün içinde', value: '3d'},
        {name: 'Son 7 gün içinde', value: '1w'},
        {name: 'Son 15 gün içinde', value: '15d'},
        {name: 'Son 30 gün içinde', value: '1m'},
    ]

    const handleClearFilter = () => {
        setText('')
        setType('')
        setDateValue('')
        setDistrict('')
        setNeighborhood('')
        setIndex(0)
    }

    const body = (
        <div className='space-y-5'>
            <div className='flex items-center gap-5 lg:gap-5 flex-wrap'>
            {
                categories.map((v, i) => (
                    <button 
                    className={cn('text-sm font-semibold duration-300 px-5 py-2 rounded-full border border-first', 
                        index === i ? 'text-white bg-first' : 'text-first')}
                    key={i} 
                    onClick={() => setIndex(i)}>
                        {v.name}
                    </button>
                ))
            }
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-5'>
                <div className='space-y-1'>
                <Label>Kategori</Label>
                <SelectComp 
                    placeholder="Kategori Seç"
                    label="Kategoriler"
                    onValueChange={(e) => setType(e)}
                    value={type}
                    data={categories[index]?.home_sub?.map((c) => ({name: c.name, value: c.id.toString()}))}/>
                </div>

                <div className='space-y-1'>
                <Label>İlan adı, ilan tipi, ilçe, mahalle...</Label>
                <Input 
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    placeholder="Bir şeyler yaz..."/>
                </div>

                <div className='space-y-1'>
                <Label>İlçe</Label>
                <SelectCompForAdres
                    data={districts}
                    onValueChange={(e) => setDistrict(e)}
                    value={district}
                    label="İlçeler"
                    placeholder="İlçe Seçin"
                />
                </div>
                <div className='space-y-1'>
                <Label>Mahalle / Semt</Label>
                <SelectCompForAdres
                    data={neighborhoods}
                    onValueChange={(e) => setNeighborhood(e)}
                    value={neighborhood}
                    label="Mahalleler/Semtler"
                    placeholder="Mahalle/Semt Seçin"
                    disabled={!district}
                />
                </div>
            </div>

            <div className='space-y-1'>
                <Label>İlan Tarihi</Label>
                <div className='flex items-center gap-3 lg:gap-5 flex-wrap'>
                {
                dateArr.map((v, i) => (
                    <label key={i} htmlFor={v.value} className="flex items-center gap-2 text-sm">
                        <input name="date" 
                        onChange={(e) => setDateValue(e.target.value)}
                        checked={v.value === dateValue}
                        value={v.value} className="accent-first" type="radio" />
                        <p>{v.name}</p>
                    </label>
                ))
            }
                </div>
            </div>
        </div>
    )


  return (
    <Modal
        title='İlan Ara'
        isOpen={open}
        onClose={onClose}
        actionLabel='Ara'
        onSubmit={handleSearch}
        body={body}
        secondaryActionLabel='Filteyi Temizle'
        secondaryAction={handleClearFilter}
    />
  )
}

export default SearchModal