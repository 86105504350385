import Image from "@/components/ui/image"
import { API_URL } from "@/config"
import { IBlog } from "@/types"
import { onlyDate } from "@/utils/helpers"
import { Calendar } from "lucide-react"
import { Link } from "react-router-dom"

const Blog = ({
    data
}: {
    data: IBlog
}) => {
  return (
    <Link to={`/haberler/${data.seo}`} className="flex gap-3 lg:gap-5 group">
        <div className="aspect-square shrink-0 h-fit w-[100px] lg:w-[150px] overflow-hidden">
            <Image src={`${API_URL}/${data.image}`}/>
        </div>
        <div className="flex flex-col justify-between w-full lg:py-3">
            <p className="line-clamp-2 max-lg:text-sm group-hover:text-first duration-300 font-semibold">
                {data.title}
            </p>
            <p className="text-xs font-medium text-third-dark line-clamp-2 lg:line-clamp-4">
                <p dangerouslySetInnerHTML={{__html: data.desc}}/>
            </p>
            <div className="flex items-center gap-2 lg:gap-3 text-xs lg:text-sm font-semibold text-third-dark">
                <Calendar size={16}/>
                <span>{onlyDate(new Date(data.createdAt))}</span>
            </div>
        </div>
    </Link>
  )
}

export default Blog