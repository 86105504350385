import { onlyDate } from "@/utils/helpers"
import { Button } from "../ui/button"
import Image from "../ui/image"
import { Link } from "react-router-dom"
import { IBlog } from "@/types"
import { API_URL } from "@/config"

const Article = ({
  data
}: {
  data: IBlog
}) => {
  const handleLocate = () => {
    window.location.href = `/haberler/${data.seo}`
  }
  return (
    <div className="w-full space-y-2 lg:space-y-3">
        <Link to={`/haberler/${data.seo}`} className="w-full flex aspect-square">
            <Image src={`${API_URL}/${data.image}`} alt={data.title}/>
        </Link>

        <p className="w-full truncate text-sm font-semibold">{data.title}</p>
        <p className="text-xs text-third-dark">{onlyDate(new Date(data.createdAt))}</p>

        <p 
        dangerouslySetInnerHTML={{__html: data.desc}}
        className="line-clamp-3 text-sm text-third-dark"
        />

        <Button onClick={handleLocate} variant={'first'}>Devamını Oku</Button>
    </div>
  )
}

export default Article