import { Facebook, Instagram, Mail, Phone } from "lucide-react";
import { Link } from "react-router-dom"
import { FaWhatsapp } from "react-icons/fa";
import Icon from "../ui/icon";
import Logo from '@/assets/icon/logo_outline.svg'
import { useCategories } from "@/contexts/CategoriesProvider";
import { useSettings } from "@/contexts/SettingsProvider";
const Footer = () => {
    const currentYear = new Date().getFullYear();

    const {categories} = useCategories()
    const {settings} = useSettings();

  return (
    <footer className="py-8 bg-primary space-y-8 text-white">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 container-wrapper gap-5">
            <div className="flex flex-col gap-5">
                <Link to={'/'} className="text-lg font-semibold text-first flex max-lg:justify-center">
                    <Icon
                        icon={<Logo/>}
                        width="120px"
                        height=""
                        color="#EB592C"
                    />
                </Link>

                <div className="flex flex-col gap-2 max-lg:text-center">
                    <Link to={'/hakkimizda'} className="text-sm hover:text-first duration-300">Hakkımızda</Link>
                    <Link to={'/iletisim'} className="text-sm hover:text-first duration-300">İletişim</Link>
                    <Link to={'/haberler'} className="text-sm hover:text-first duration-300">Emlak Haberleri</Link>
                </div>
            </div>
            {
                categories.map((v, i) => (
                    <div key={i} className="flex flex-col gap-5">
                        <Link to={`/${v.seo}`} className="text-lg font-semibold text-first max-lg:text-center">{v.name}</Link>

                        <div className="flex flex-col gap-2 max-lg:text-center">
                            {
                                v.home_sub.map((h, i) => (
                                    <Link key={i} to={`/${h.seo}`} 
                                    className="text-sm hover:text-first duration-300">
                                        {h.name}
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
            <div className="flex flex-col gap-5">
                <Link to={'/'} className="text-lg font-semibold text-first max-lg:text-center">İletişim</Link>

                <div className="flex flex-col gap-3 max-lg:items-center">
                    <Link to={`tel:${settings?.phone}`} className="text-sm hover:text-first duration-300 flex items-center gap-2">
                    <Phone size={24}/> {settings?.phone}
                    </Link>
                    <Link to={`https://wa.me/${settings?.whatsApp}`} 
                    className="text-sm hover:text-first duration-300 flex items-center gap-2">
                        <FaWhatsapp size={24}/> WhatsApp
                    </Link>
                    <Link to={`mailto:${settings?.mail}`} 
                    className="text-sm hover:text-first duration-300 flex items-center gap-2">
                        <Mail size={24}/> {settings?.mail}
                    </Link>
                    <Link to={settings?.instagram || '/'} className="text-sm hover:text-first duration-300 flex items-center gap-2"><Instagram size={24}/> Instagram</Link>
                    <Link to={settings?.facebook || '/'} className="text-sm hover:text-first duration-300 flex items-center gap-2"><Facebook size={24}/> Facebook</Link>
                </div>
            </div>
        </div>

        <div className="container-wrapper pt-8 border-t border-first text-sm font-semibold max-lg:text-center">
            <Link to={'https://dijitalyanki.com'} className="text-first font-semibold">Dijital Yankı</Link> &copy; {currentYear} | Tüm hakları saklıdr.
        </div>
    </footer>
  )
}

export default Footer