/* eslint-disable @typescript-eslint/no-explicit-any */
import Heading from "@/components/customUI/Heading"
import SelectComp from "@/components/customUI/SelectComp"
import { cn } from "@/lib/utils"
import { List, SearchX, Square } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import SquareAdvert from "@/components/customUI/Advert"
import Advert from "../CategoryPage/Advert"
import { IListing } from "@/types"
import request from "@/utils/request"
import AdvertLoading from "@/components/customUI/LoadingSkeletons/AdvertLoading"
import { PaginationComp } from "@/components/customUI/PaginationComp"
import VerticalAdvertLoading from "@/components/customUI/LoadingSkeletons/VerticalAdvertLoading"
import { Helmet } from "react-helmet-async"
import { company_name, DOMAIN_URL } from "@/config"

const ShowCasePage = () => {

    const [listType, setListType] = useState(1)

    const [data, setData] = useState<IListing[]>([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [sortValue, setSortValue] = useState('')

    const handleGetData = useCallback(async () => {
        try {
            setLoading(true)
            const params : {
                page?: number,
                sortBy?: string,
                sortOrder?: string
            } = {}

            if(page) params.page = page
            if(sortValue) params.sortBy = sortValue === 'newest' || sortValue === 'oldest' ? 'createdAt' : 'price'
            if(sortValue) params.sortOrder = sortValue === 'newest' || sortValue === 'highest' ? 'DESC' : 'ASC'

            const res = await request({url: `/listing/highlights`, params, method: 'get'})
            setData(res.data.data)
            setTotalPages(res.data.totalPages)
            setTotal(res.data.total)
        } catch (error: any) {
            throw new Error(error)
        } finally {
            setLoading(false)
        }
    }, [page, sortValue])

    useEffect(() => {
        handleGetData()
    }, [handleGetData])

    const sort = [
        {name: 'Yeniden Eskiye', value: 'newest'},
        {name: 'Eskiden Yeniye', value: 'oldest'},
        {name: 'Artan Fiyat', value: 'lowest'},
        {name: 'Azalan Fiyat', value: 'highest'},
    ]

  return (
    <>
    <Helmet>
        <title>Öne Çıkan İlanlar | {company_name}</title>
        <meta name="description" content={`${company_name}’ın öne çıkan ilanlarını keşfedin. Sakarya'daki en iyi fırsatlar için kiralık ve satılık mülklerimizi inceleyin.`} />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content="öne çıkan ilanlar, ${company_name}, Sakarya emlak, kiralık daire, satılık daire, öne çıkan fırsatlar" />
        <meta property="og:title" content={`Öne Çıkan İlanlar | ${company_name}`} />
        <meta property="og:description" content={`${company_name}’ın öne çıkan ilanlarını keşfedin. Sakarya'daki en iyi fırsatlar için kiralık ve satılık mülklerimizi inceleyin.`} />
        <meta property="og:url" content={`${DOMAIN_URL}/vitrin`} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={company_name} />
    </Helmet>

    <main className="py-5 lg:py-10 space-y-5 container-wrapper">
        <div className="flex justify-between items-center pb-3 border-b border-border gap-3">
            <Heading title={`${total} vitrin ilanı`}/>
            <div className="flex items-center gap-5">
            <div className="flex items-center gap-2 max-lg:hidden">
                <button 
                onClick={() => setListType(0)}
                className={cn(listType === 0 ? 'text-first' : 'text-primary', 'duration-300')}>
                    <List/>
                </button>
                <button 
                onClick={() => setListType(1)}
                className={cn(listType === 1 ? 'text-first' : 'text-primary', 'duration-300')}>
                    <Square/>
                </button>
            </div>
            <div className="w-[200px]">
                <SelectComp 
                label="Sırala" 
                onValueChange={(e) => setSortValue(e)}
                value={sortValue}
                data={sort}
                placeholder="Sırala"/>
            </div>
            </div>
        </div>

        {
            !loading ?
            listType === 0 ?
            (
                data.length > 0 ?
                <div className="flex flex-col gap-3 w-full lg:min-h-[600px]">
                    {
                        data.map((v, i) => (
                            <Advert
                                key={i}
                                data={v}
                            />
                        ))
                    }
                </div>
                :
                <div className="flex min-h-[350px] items-center justify-center flex-col gap-3 w-full h-full text-lg font-semibold text-[#A9B0B9]">
                    <SearchX size={30}/>
                    <p>Sonuç bulunamadı...</p>
                </div>
            )
            :
            (
                data.length > 0 ?
                (
                    <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 place-content-start">
                        {
                            data.map((v, i) => (
                                <SquareAdvert
                                    key={i}
                                    data={v}
                                    />
                            ))
                        }
                    </div>
                )
                :
                <div className="flex min-h-[350px] items-center justify-center flex-col gap-3 w-full h-full text-lg font-semibold text-[#A9B0B9]">
                    <SearchX size={30}/>
                    <p>Sonuç bulunamadı...</p>
                </div>
            )
            :
            listType === 0 ?
            (
                <div className="flex flex-col gap-3 w-full lg:min-h-[600px]">
                    {
                        [...Array(20)].map((_, i) => (
                        <VerticalAdvertLoading key={i}/>
                        ))
                    }
                </div>
            )
            :
            <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 place-content-start">
            {
                [...Array(20)].map((_, i) => (
                <AdvertLoading key={i}/>
                ))
            }
            </div>
        }

        {
            totalPages > 0 &&
            (
                <PaginationComp
                    setPage={setPage}
                    currentPage={page}
                    totalPages={totalPages}
                />
            )
        }
    </main>
    </>
  )
}

export default ShowCasePage