import { company_name, DOMAIN_URL } from "@/config"
import { useSettings } from "@/contexts/SettingsProvider"
import { extractSrcFromIframe } from "@/utils/helpers"
import { Facebook, Instagram, Mail, Map, Phone } from "lucide-react"
import { Helmet } from "react-helmet-async"
import { FaWhatsapp } from "react-icons/fa"
import { Link } from "react-router-dom"

const Contact = () => {

    const {settings} = useSettings()

  return (
    <>
    <Helmet>
      <title>İletişim | {company_name}</title>
      <meta name="description" content={`${company_name} ile iletişime geçmek için 0555 555 55 55 telefon numaramızdan bize ulaşabilirsiniz. Sakarya'da en iyi emlak hizmetleri için buradayız.`} />
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={`İletişim, ${company_name}, Sakarya emlak, iletişim formu, emlak hizmetleri`} />
      <meta property="og:title" content={`İletişim | ${company_name}`} />
      <meta property="og:image" content={`${DOMAIN_URL}/assets/icon/logo.svg`} />
      <meta property="og:description" content={`${company_name} ile iletişime geçmek için telefon numaramızdan bize ulaşabilirsiniz. Sakarya'da en iyi emlak hizmetleri için buradayız.`} />
    </Helmet>
    <main className="lg:min-h-[485px] py-5 lg:py-10 container-wrapper grid-cols-1 grid gap-5">
        <section className="gap-5 lg:p-5 grid grid-cols-1 lg:grid-cols-2 px-5">
            <h1 className="lg:text-xl font-semibold lg:col-span-2 text-center">İletişim</h1>

            <Link to={`tel:${settings?.phone}`} className="flex items-center gap-2 hover:text-first duration-300 text-third-dark font-semibold text-sm">
                <Phone size={28}/>
                <p>{settings?.phone}</p>
            </Link>
            <Link to={`mailto:${settings?.mail}`} className="flex items-center gap-2 hover:text-first duration-300 text-third-dark font-semibold text-sm">
                <Mail size={28}/>
                <p>{settings?.mail}</p>
            </Link>
            <Link to={`https://wa.me/${settings?.whatsApp}`}  className="flex items-center gap-2 hover:text-first duration-300 text-third-dark font-semibold text-sm">
                <FaWhatsapp size={28}/>
                <p>WhatsApp</p>
            </Link>
            <Link to={settings?.facebook || '/'} className="flex items-center gap-2 hover:text-first duration-300 text-third-dark font-semibold text-sm">
                <Facebook size={28}/>
                <p>Facebook</p>
            </Link>
            <Link to={settings?.instagram || '/'} className="flex items-center gap-2 hover:text-first duration-300 text-third-dark font-semibold text-sm">
                <Instagram size={28}/>
                <p>Instagram</p>
            </Link>

            <div className="flex items-center gap-2 text-wrap hover:text-first duration-300 text-third-dark font-semibold text-sm">
                <Map size={28} className="shrink-0"/> VATAN MAH. VATAN CAD, 4229. Sk. NO /9 / A, 54100 Serdivan/Sakarya
            </div>
        </section>
        <section className="aspect-[3/1] w-full">
        {settings?.location &&
        (<iframe 
        src={extractSrcFromIframe(settings?.location || '/')} 
        width="100%" 
        height="100%"/>)
        }
        </section>
    </main>
    </>
  )
}

export default Contact