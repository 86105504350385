/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';
import { CategoriesProps } from '@/types';
import request from '@/utils/request';

interface CategoryContextType {
    categories: CategoriesProps[];
    loading: boolean;
}

const CategoryContext = createContext<CategoryContextType | undefined>(undefined);

export const CategoryProvider = ({ children }: { children: ReactNode }) => {
  const [categories, setCategories] = useState<CategoriesProps[]>([]);
  const [loading, setLoading] = useState(true);
  

  const handleGetCategories = useCallback(async() => {
    try {
      setLoading(true);
        const res = await request({url: `/home/categories`, method: 'get'})
        setCategories(res.data)
    } catch (error: any) {
        throw new Error(error)
    } finally {
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    handleGetCategories()
  }, [handleGetCategories])
  

  return (
    <CategoryContext.Provider value={{ categories, loading }}>
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategories = (): CategoryContextType => {
  const context = useContext(CategoryContext);
  if (!context) {
    throw new Error('user must be used within a UserProvider');
  }
  return context;
};