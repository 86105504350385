import { BrowserRouter, Route, Routes } from "react-router-dom"
import BaseLayout from "./components/layout/BaseLayout"
import Home from "./components/pages/Home"
import AdvertPage from "./components/pages/AdvertPage"
import CategoryPage from "./components/pages/CategoryPage"
import ShowCasePage from "./components/pages/Showcase"
import BlogPage from "./components/pages/BlogPage"
import DetailPage from "./components/pages/BlogPage/Detail"
import AboutUs from "./components/pages/AboutUs"
import Contact from "./components/pages/Contact"
import { CategoryProvider } from "./contexts/CategoriesProvider"
import { Toaster } from "@/components/ui/toaster"
import { SettingsProvider } from "./contexts/SettingsProvider"
import { HelmetProvider } from 'react-helmet-async';
import NotFoundPage from "./components/pages/NotFoundPage"

function App() {

  return (
    <HelmetProvider>
      {/* <AuthProvider> */}
        <SettingsProvider>
          <CategoryProvider>
            <BrowserRouter>
              <Routes>
                <Route element={<BaseLayout/>}>
                  <Route element={<Home/>} path="/"/>
                  <Route element={<AdvertPage/>} path="/ilan/:seo"/>
                  <Route element={<CategoryPage/>} path="/:seo"/>
                  <Route element={<ShowCasePage/>} path="/vitrin"/>
                  <Route element={<BlogPage/>} path="/haberler"/>
                  <Route element={<DetailPage/>} path="/haberler/:seo"/>
                  <Route element={<Contact/>} path="/iletisim"/>
                  <Route element={<AboutUs/>} path="/hakkimizda"/>
                  <Route element={<NotFoundPage/>} path="/404"/>
                </Route>
              </Routes>
            </BrowserRouter>
            <Toaster />
            {/* <RegisterModal/>
            <LoginModal/> */}
          </CategoryProvider>
        </SettingsProvider>
      {/* </AuthProvider> */}
    </HelmetProvider>
  )
}

export default App
