import { Skeleton } from "@/components/ui/skeleton"

const PopularLoading = () => {
  return (
    <div className="w-full flex gap-5">
        <Skeleton className="w-[90px] h-[90px] shrink-0"/>
        <div className="flex flex-col justify-between w-full gap-3">
            <div className="space-y-1">
                <Skeleton className="h-3 w-full rounded-full"/>
                <Skeleton className="h-3 w-3/4 rounded-full"/>
                <Skeleton className="h-3 w-2/4 rounded-full"/>
            </div>
                <Skeleton className="h-4 w-2/4 rounded-full"/>
        </div>
    </div>
  )
}

export default PopularLoading