import { Skeleton } from "@/components/ui/skeleton"

const VerticalAdvertLoading = () => {
  return (
    <div className="w-full border border-border rounded-xl overflow-hidden flex">
        <div className="w-[240px] flex shrink-0 aspect-[240/160] relative">
            <Skeleton className="w-full h-full"/>
        </div>
        <div className="p-5 space-y-3 w-full">
            <div className="flex items-center w-full justify-between gap-5">
                <Skeleton className="w-3/4 h-5 rounded-full"/>
                <Skeleton className="w-6 h-6"/>
            </div>
            <Skeleton className="w-20 h-4 rounded-full"/>
            <Skeleton className="w-1/2 h-6 rounded-full"/>
            <Skeleton className="w-1/4 h-7 rounded-full"/>
        </div>
    </div>
  )
}

export default VerticalAdvertLoading