import Advert from "@/components/customUI/Advert"
import Heading from "@/components/customUI/Heading"
import { Button } from "@/components/ui/button"
import { IListing } from "@/types"
import Slider from "react-slick"
import { settings } from "./slider-settings"
import AdvertLoading from "@/components/customUI/LoadingSkeletons/AdvertLoading"

const Featured = ({
  highlights,
  loading
}: {
  highlights: IListing[]
  loading: boolean;
}) => {
  const handleLocate = () => {
    window.location.href = '/vitrin'
  }
  return (
    <section className="container-wrapper space-y-3 lg:space-y-5 max-lg:px-2">
        <div className="flex items-center justify-between">
            <Heading title="Öne Çıkan İlanlar"/>
            <Button onClick={handleLocate} variant={'first'}>Tümünü Gör</Button>
        </div>

        {
          !loading ?
          highlights.length > 3 ?
          <div className="w-full pb-10">
            <Slider {...settings}>
              {
                highlights?.map((v, i) => (
                  <div key={i} className="px-2 w-full">
                    <Advert
                        data={v}
                    />
                  </div>
                ))
              }
            </Slider>
          </div>
          :
          <div className="w-full pb-10 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid gap-2 lg:gap-4">
              {
                highlights?.map((v, i) => (
                  <div key={i} className="px-2 w-full">
                    <Advert
                        data={v}
                    />
                  </div>
                ))
              }
          </div>
          :
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
            {
              [...Array(4)].map((_, i) => (
                <AdvertLoading key={i}/>
              ))
            }
          </div>
        }
    </section>
  )
}

export default Featured