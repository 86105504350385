import SelectComp from "@/components/customUI/SelectComp"
import SelectCompForAdres from "@/components/customUI/SelectCompForAdres"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { District, Neighborhoods } from "."
import { SetURLSearchParams } from "react-router-dom"
import { CategoriesProps } from "@/types"
import { Trash } from "lucide-react"

const FilterSide = ({
    districts,
    setDistrict,
    district,
    neighborhood,
    setNeighborhood,
    neighborhoods,
    selectedCategory,
    setSelectedCategory,
    dateValue,
    setDateValue,
    searchText,
    setSearchText,
    price_min,
    setPriceMin,
    price_max,
    setPriceMax,
    query_desc,
    setQueryDesc,
    setSearchParams,
    searchParams,
    category
}: {
    neighborhood: string,
    setNeighborhood: React.Dispatch<React.SetStateAction<string>>
    districts: District[],
    setDistrict: React.Dispatch<React.SetStateAction<string>>
    district: string
    neighborhoods: Neighborhoods[]
    selectedCategory: string
    setSelectedCategory: React.Dispatch<React.SetStateAction<string>>
    dateValue: string
    setDateValue: React.Dispatch<React.SetStateAction<string>>
    searchText: string
    setSearchText: React.Dispatch<React.SetStateAction<string>>
    price_min: string
    setPriceMin: React.Dispatch<React.SetStateAction<string>>
    price_max: string
    setPriceMax: React.Dispatch<React.SetStateAction<string>>
    query_desc: string
    setQueryDesc: React.Dispatch<React.SetStateAction<string>>
    setSearchParams: SetURLSearchParams,
    searchParams: URLSearchParams
    category: CategoriesProps | undefined
}) => {

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQueryDesc(e.target.checked ? 'true' : '');
      };

    const dateArr = [
        {name: 'Son 24 saat', value: '24h'},
        {name: 'Son 3 gün içinde', value: '3d'},
        {name: 'Son 7 gün içinde', value: '1w'},
        {name: 'Son 15 gün içinde', value: '15d'},
        {name: 'Son 30 gün içinde', value: '1m'},
    ]

    const addQueryParams = () => {
        const currentParams = Object.fromEntries([...searchParams]);
    
        const newParams = {
            category: selectedCategory || undefined,
            date: dateValue || undefined,
            district_id: district || undefined,
            neighborhood_id: neighborhood || undefined,
            query_text: searchText || undefined,
            query_desc: query_desc || undefined,
            price_min: price_min || undefined,
            price_max: price_max || undefined
        };
    
        // Parametreleri güncelle
        for (const [key, value] of Object.entries(newParams)) {
            if (value) {
                currentParams[key] = value;  // Eğer değer varsa ekle
            } else {
                delete currentParams[key];  // Eğer yoksa, URL'den çıkar
            }
        }
    
        setSearchParams(currentParams);
    };

  return (
    <aside className="w-[250px] max-lg:hidden h-fit shrink-0 p-5 space-y-5 border border-border rounded-xl bg-third">
        <div className="space-y-3">
            <div className="flex items-center justify-between">
                <p className="text-sm font-semibold">Adres</p>
                {
                    district || neighborhood ? (
                        <button onClick={() => {setDistrict(''); setNeighborhood('')}} className="p-2 bg-red-400 text-white rounded-md">
                            <Trash size={14}/>
                        </button>
                    )
                    :
                    null
                }
            </div>
            <SelectCompForAdres
                data={districts}
                onValueChange={(e) => setDistrict(e)}
                value={district}
                label="İlçeler"
                placeholder="İlçe Seçin"
            />
            <SelectCompForAdres
                data={neighborhoods}
                onValueChange={(e) => setNeighborhood(e)}
                value={neighborhood}
                label="Mahalleler/Semtler"
                placeholder="Mahalle/Semt Seçin"
                disabled={!district}
            />
        </div>

        <div className="space-y-3">
            <div className="flex items-center justify-between">
                <p className="text-sm font-semibold">Kategori</p>
                {
                    selectedCategory && (
                        <button onClick={() => setSelectedCategory('')} className="p-2 bg-red-400 text-white rounded-md">
                            <Trash size={14}/>
                        </button>
                    )
                }
            </div>
            <SelectComp
                data={category?.sub_categories.map((c) => ({name: c.name, value: c.id.toString()})) || []}
                placeholder="Kategori Seç"
                label="Kategoriler"
                onValueChange={(e) => setSelectedCategory(e)}
                value={selectedCategory}
                disabled={!category}
            />
        </div>

        <div className="space-y-3">
            <div className="flex items-center justify-between">
                <p className="text-sm font-semibold">Fiyat</p>
                {
                    price_max || price_min ? (
                        <button onClick={() => {setPriceMax(''); setPriceMax('')}} className="p-2 bg-red-400 text-white rounded-md">
                            <Trash size={14}/>
                        </button>
                    )
                    :
                    null
                }
            </div>
            <Input
                placeholder="En az"
                type="number"
                min={0}
                onChange={(e) => setPriceMin(e.target.value)}
                value={price_min}
            />
            <Input
                placeholder="En çok"
                type="number"
                onChange={(e) => setPriceMax(e.target.value)}
                value={price_max}
            />
        </div>

        <div className="space-y-3">
            <div className="flex items-center justify-between">
                <p className="text-sm font-semibold">İlan Tarihi</p>

                {
                    dateValue && (
                        <button onClick={() => setDateValue('')} className="p-2 bg-red-400 text-white rounded-md">
                            <Trash size={14}/>
                        </button>
                    )
                }
            </div>
            <div className="flex flex-col gap-1">
                {
                    dateArr.map((v, i) => (
                        <label key={i} htmlFor={v.value} className="flex items-center gap-2 text-sm">
                            <input name="date" 
                            onChange={(e) => setDateValue(e.target.value)}
                            checked={v.value === dateValue}
                            value={v.value} className="accent-first" type="radio" />
                            <p>{v.name}</p>
                        </label>
                    ))
                }
            </div>
        </div>

        <div className="space-y-3">
            <div className="flex items-center justify-between">
                <p className="text-sm font-semibold">Kelime ile filtele</p>
                {
                    searchText && (
                        <button 
                        onClick={() => setSearchText('')} 
                        className="p-2 bg-red-400 text-white rounded-md">
                            <Trash size={14}/>
                        </button>
                    )
                }
            </div>    
            <Input 
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                placeholder="Bir şeyler yaz..."/>
            <label className="flex items-center gap-2 text-xs" htmlFor="desc">
                <input 
                type="checkbox" 
                name="desc" 
                id="desc" 
                checked={query_desc === 'true'}
                onChange={handleCheckboxChange}
                className="accent-first" />
                <p>İlan açıklamalırını dahil et.</p>
            </label>
        </div>

        <Button onClick={addQueryParams} className="w-full" variant={'first'}>Filtrele</Button>
    </aside>
  )
}

export default FilterSide