import { IFeatureForListing } from "@/types"
import { Dot } from "lucide-react"

const Properties = ({
    props
}: {
    props: IFeatureForListing[]
}) => {

  return (
    <div className="p-5 space-y-5 min-h-[350px] h-fit">
        {
            props?.map((v, i) => (
                <div key={i} className="space-y-3 w-full text-sm text-third-dark">
                    <b>{v.feature.name}</b>
                    <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-2">
                        {
                            v.featureValues?.map((k, i) => (
                                <div key={i} className="flex items-center">
                                    <Dot/>
                                    <p>{k.value}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            ))
        }
    </div>
  )
}

export default Properties