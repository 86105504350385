import { Skeleton } from "@/components/ui/skeleton"

const BlogLoading = () => {
  return (
    <div className="flex gap-3 lg:gap-5 group">
        <div className="aspect-square shrink-0 h-fit w-[100px] lg:w-[150px] overflow-hidden">
            <Skeleton className="w-full h-full"/>
        </div>
        <div className="flex flex-col justify-between w-full lg:py-3">
            <div className="space-y-1">
                <Skeleton className="w-full lg:w-3/4 h-4 lg:h-6 rounded-full"/>
                <Skeleton className="w-3/4 h-4 lg:h-6 rounded-full lg:hidden"/>
            </div>
            <div className="space-y-1">
                <Skeleton className="w-full h-3 rounded-full"/>
                <Skeleton className="w-3/4 h-3 rounded-full"/>
                <Skeleton className="w-2/4 max-lg:hidden h-3 rounded-full"/>
                <Skeleton className="w-1/4 max-lg:hidden h-3 rounded-full"/>
            </div>
            <Skeleton className="w-1/4 h-4 lg:h-5 rounded-full"/>
        </div>
    </div>
  )
}

export default BlogLoading