import { Skeleton } from "@/components/ui/skeleton"

const AdvertLoading = () => {
  return (
    <div className="w-full h-full p-2 lg:p-3 rounded-xl border border-border space-y-2 lg:space-y-3 relative">
        <div className="w-full flex aspect-[1.25/1] rounded-xl overflow-hidden">
            <Skeleton className="w-full h-full"/>
        </div>

        <Skeleton className="w-full h-5 rounded-full"/>
        <Skeleton className="w-1/2 h-4 rounded-full"/>
        <Skeleton className="w-3/4 h-6 rounded-full"/>
        <div className="flex items-center gap-2 justify-between flex-wrap">
            <Skeleton className="w-[100px] h-7 rounded-full"/>
            <Skeleton className="w-[80px] h-4 rounded-full"/>
        </div>
    </div>
  )
}

export default AdvertLoading