import { Skeleton } from "@/components/ui/skeleton"

const ArticleLoading = () => {
  return (
    <div className="w-full space-y-2 lg:space-y-3">
        <div className="w-full flex aspect-square">
            <Skeleton className="w-full h-full rounded-none"/>
        </div>

        <Skeleton className="w-full h-5 rounded-full"/>
        <Skeleton className="w-[70px] h-4 rounded-full"/>

        <div className="space-y-1">
            <Skeleton className="w-full h-3 rounded-full"/>
            <Skeleton className="w-3/4 h-3 rounded-full"/>
            <Skeleton className="w-2/4 h-3 rounded-full"/>
        </div>
        <Skeleton className="w-[130px] h-10 rounded-md"/>
    </div>
  )
}

export default ArticleLoading