import { company_name, DOMAIN_URL } from "@/config"
import { Helmet } from "react-helmet-async"

const AboutUs = () => {
  return (
    <>
    <Helmet>
    <title>Hakkımızda | {company_name}</title>
    <meta name="description" content={`${company_name}, Sakarya'da emlak sektöründe uzmanlaşmış bir firmadır. Müşterilerimize en iyi hizmeti sunmayı hedefliyoruz. Emlak ihtiyaçlarınız için bize ulaşın!`} />
    <meta name="robots" content="index, follow" />
    <meta name="keywords" content="Hakkımızda, ${company_name}, Sakarya emlak, emlak firması, emlak hizmetleri, kiralık daire, satılık daire, arsa, Sakarya kiralık emlak, gayrimenkul" />
    <meta property="og:title" content={`Hakkımızda | ${company_name}`} />
    <meta property="og:description" content={`${company_name}, Sakarya'da emlak sektöründe uzmanlaşmış bir firmadır. Müşterilerimize en iyi hizmeti sunmayı hedefliyoruz.`} />
    <meta property="og:type" content="website" />
    <meta property="og:image" content={`${DOMAIN_URL}/assets/icon/logo.svg`} />
    <meta property="og:site_name" content={company_name} />
    </Helmet>
    <main className="lg:min-h-[485px] container-wrapper py-5 lg:p-10 space-y-5">
        <h1 className="lg:text-2xl font-semibold text-center text-third-dark">{company_name}</h1>
        <section className="text-sm text-third-dark space-y-3">
        <p><strong>{company_name}</strong> olarak, Sakarya'nın emlak sektöründe yıllara dayanan tecrübemiz ve güvenilir hizmet anlayışımız ile faaliyet göstermekteyiz. Sakarya'da gayrimenkul alım satımı, kiralama ve danışmanlık hizmetlerinde profesyonel çözümler sunarak, müşterilerimizin memnuniyetini her zaman ön planda tutuyoruz.</p>
        
        <p>Şirketimiz, Sakarya'nın hızla gelişen ve değer kazanan bölgelerinde, konut, iş yeri, arsa ve ticari mülklerle ilgili geniş portföyüyle müşterilerine en uygun gayrimenkul fırsatlarını sunmaktadır. {company_name}, yatırımcılar için değerli fırsatlar yaratmak ve gayrimenkul arayışında olan bireyler için en doğru kararları vermelerine yardımcı olmak için özveriyle çalışır.</p>

        <h2 className="text-lg font-semibold">Misyonumuz</h2>
        <p>{company_name} olarak misyonumuz, müşterilerimize güvenilir, şeffaf ve profesyonel bir hizmet sunmaktır. Emlak sektöründe güveni tesis etmek ve müşterilerimizin hayatlarında önemli bir karar olan gayrimenkul alım-satım süreçlerinde yanlarında yer almak en büyük hedefimizdir. Müşteri memnuniyetini en üst düzeyde tutarak, Sakarya’da en çok tercih edilen emlak firmalarından biri olmayı amaçlıyoruz.</p>

        <h3 className="text-lg font-semibold">Vizyonumuz</h3>
        <p>Gayrimenkul sektöründe yenilikçi ve kaliteli hizmet anlayışı ile Sakarya’nın lider emlak firmalarından biri olmayı hedefliyoruz. Yılların getirdiği bilgi birikimi ve deneyim ile sürekli gelişim göstererek, hem yerel hem de ulusal düzeyde örnek alınan bir marka olma vizyonunu benimsiyoruz.</p>

        <h4 className="text-lg font-semibold">Neden {company_name}?</h4>
        <p>Sakarya’da gayrimenkul denildiğinde akla gelen ilk firmalardan biri olan {company_name}, sunduğu güvenilir ve şeffaf hizmet anlayışıyla sektörde öne çıkmaktadır. Müşteri odaklı hizmet anlayışımız, doğru fiyat politikalarımız ve geniş emlak portföyümüz sayesinde her türlü gayrimenkul ihtiyacınıza uygun çözümler üretmekteyiz. Her zaman müşterilerimizin ihtiyaçlarını anlayarak, onlara en uygun ve kârlı yatırım fırsatlarını sunuyoruz.</p>

        <h5 className="text-lg font-semibold">Hizmetlerimiz</h5>
        <p>{company_name} olarak geniş hizmet yelpazemiz ile her türlü gayrimenkul ihtiyacınıza yanıt veriyoruz. Sakarya’da ev almak isteyenler için geniş bir konut portföyü sunarken, iş yeri ve arsa arayışında olan yatırımcılara en kârlı fırsatları sağlamaktayız. Kiralama süreçlerinde ise hem kiracılar hem de ev sahipleri için sorunsuz bir süreç yönetimi sunuyoruz. Ayrıca, yatırımcılar için gayrimenkul danışmanlığı hizmeti vererek, en doğru yatırım kararlarını almalarına yardımcı oluyoruz.</p>

        <h6 className="text-lg font-semibold">Sakarya'da Gayrimenkul Yatırımının Avantajları</h6>
        <p>Sakarya, son yıllarda gelişen altyapısı, doğal güzellikleri ve İstanbul’a yakın konumu sayesinde gayrimenkul yatırımında önemli bir merkez haline gelmiştir. Özellikle konut, arsa ve ticari gayrimenkuller açısından hızla değer kazanan bölgeleri ile yatırımcılar için cazip fırsatlar sunmaktadır. {company_name} olarak, Sakarya’nın en değerli bölgelerinde yer alan fırsatları sizlerle buluşturarak, yatırım yaparken en doğru adımları atmanıza yardımcı oluyoruz.</p>

        <h6 className="text-lg font-semibold">Değerlerimiz</h6>
        <p>{company_name} olarak temel değerlerimiz; dürüstlük, güvenilirlik, şeffaflık ve müşteri memnuniyetidir. İşimizi yaparken her zaman etik kurallara uygun hareket eder ve müşterilerimizin çıkarlarını en üst seviyede tutarız. Her bir müşterimize özel ilgi gösterir, onların beklentilerine en uygun çözümleri sunarız.</p>

        <h6 className="text-lg font-semibold">Müşteri Memnuniyeti</h6>
        <p>Bizim için müşteri memnuniyeti her şeyin önünde gelir. {company_name} olarak, Sakarya’da binlerce müşteriye hizmet vermiş olmanın gururunu yaşıyoruz. Her bir müşterimizin ihtiyaçlarına en uygun çözümü sunmak, onların güvenini kazanmak ve memnuniyetlerini sağlamak için çalışmalarımızı titizlikle yürütüyoruz. Amacımız, uzun vadeli ilişkiler kurarak, her zaman sizin yanınızda olabilmektir.</p>

        <h6 className="text-lg font-semibold">Gündoğdu Emlak İle Geleceğinize Yatırım Yapın</h6>
        <p>Sakarya’daki emlak sektörünün güvenilir ismi {company_name}, sizlere sadece bir gayrimenkul sunmuyor, aynı zamanda geleceğinize yatırım yapma fırsatı sunuyor. Sakarya’nın hızla değer kazanan bölgelerinde, profesyonel ekibimizle en doğru gayrimenkul çözümlerini sizlere ulaştırıyoruz. Emlak sektöründeki yenilikleri ve fırsatları takip ederek, siz değerli müşterilerimize en iyi hizmeti vermeye devam ediyoruz.</p>

        <p>Siz de hayalinizdeki evi ya da yatırım fırsatını bulmak için {company_name}’a güvenin ve Sakarya’da kazançlı bir gayrimenkul yatırımının kapılarını aralayın.</p>

        <h6 className="text-lg font-semibold">İletişim</h6>
        <p>{company_name} ile iletişime geçerek, gayrimenkul alım, satım, kiralama veya danışmanlık hizmetleri hakkında detaylı bilgi alabilirsiniz. Ofisimize gelerek ya da telefon ve e-posta aracılığıyla bize ulaşabilir, uzman ekibimizle birlikte ihtiyaçlarınıza en uygun çözümleri bulabilirsiniz.</p>
    </section>
    </main>
    </>
  )
}

export default AboutUs
