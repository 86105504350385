import { useState } from "react"
import { Button } from "../ui/button"
import { Menu, X } from "lucide-react"
import { cn } from "@/lib/utils"
import { Link } from "react-router-dom"
import Icon from "../ui/icon"
import Logo from '@/assets/icon/logo.svg'
import { useCategories } from "@/contexts/CategoriesProvider"
import Image from "../ui/image"
import { API_URL } from "@/config"

const NavMenu = () => {

    const [open, setOpen] = useState(false)
    const {categories} = useCategories()

  return (
    <>
        <Button 
        onClick={() => setOpen(!open)}
        className="text-first lg:hidden">
            <Menu/>
        </Button> 
        
        <div 
        onClick={() => setOpen(!open)}
        className={cn('fixed top-40 bg-black/50 z-50 inset-0 animate-fade', !open && 'hidden')}/>

        <div className={cn('fixed top-0 w-[250px] p-5 rounded-r-xl space-y-5 z-50 bg-white h-full duration-300', open ? 'left-0' : '-left-full')}>
            <div className="flex items-center justify-between">
                <Link onClick={() => setOpen(false)} to={'/'}>
                    <Icon width="100px" height="" icon={<Logo/>}/>
                </Link>

                <button onClick={() => setOpen(false)}>
                    <X/>
                </button>
            </div>

            <div className="flex flex-col gap-2 h-[calc(100%_-_55px)] overflow-y-auto">
                {
                    categories.map((v, i) => (
                        <div key={i} className="space-y-1">
                            <Link onClick={() => setOpen(false)} to={`/${v.seo}`} className="bg-first w-full flex p-2 rounded-xl text-sm font-semibold">
                                {v.name}
                            </Link>
                            {v.home_sub.map((h, i) => (
                                <div key={i} className="pl-2">
                                    <Link onClick={() => setOpen(false)} to={`/${h.seo}`} className=" bg-first w-full flex p-2 items-center gap-2 rounded-xl text-xs font-semibold">
                                    {h.image && (
                                        <Image src={`${API_URL}/${h.image}`} width="24px"/>
                                    )}
                                    <p>{h.name}</p>
                                </Link>
                                </div>
                            ))}
                        </div>
                    ))
                }
                <Link 
                onClick={() => setOpen(false)} 
                to={`/haberler`} 
                className="bg-first w-full flex p-2 rounded-xl text-sm font-semibold">
                    Emlak Haberleri
                </Link>
                <Link 
                onClick={() => setOpen(false)} 
                to={`/hakkimizda`} 
                className="bg-first w-full flex p-2 rounded-xl text-sm font-semibold">
                    Hakkımızda
                </Link>
                <Link 
                onClick={() => setOpen(false)} 
                to={`/iletisim`} 
                className="bg-first w-full flex p-2 rounded-xl text-sm font-semibold">
                    İletişim
                </Link>
            </div>
        </div>
    </>
  )
}

export default NavMenu