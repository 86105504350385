import Article from "@/components/customUI/Article"
import Heading from "@/components/customUI/Heading"
import { Button } from "@/components/ui/button"
import { IBlog } from "@/types"
import Slider from "react-slick"
import { settings } from "./slider-settings"
import ArticleLoading from "@/components/customUI/LoadingSkeletons/ArticleLoading"

const Articles = ({
  blogs,
  loading
}: {
  blogs: IBlog[]
  loading: boolean
}) => {
  const handleLocate = () => {
    window.location.href = '/haberler'
  }
  return (
    <section className="container-wrapper space-y-3 lg:space-y-5 max-lg:px-2">
        <div className="flex items-center justify-between">
            <Heading title="Emlak Haberleri"/>
            <Button onClick={handleLocate} variant={'first'}>Tümünü Gör</Button>
        </div>

        {
          !loading ?
          (
          <div className="w-full pb-10">
            <Slider {...settings}>
              {
                blogs?.map((v, i) => (
                  <div key={i} className="px-2 w-full">
                    <Article
                        data={v}
                    />
                  </div>
                ))
              }
            </Slider>
          </div>
          )
          :
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
            {
              [...Array(4)].map((_, i) => (
                <ArticleLoading key={i}/>
              ))
            }
          </div>
        }
    </section>
  )
}

export default Articles