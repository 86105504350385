import { extractSrcFromIframe } from "@/utils/helpers";

const Map = ({
  link
}: {
  link: string;
}) => {
  return (
    <div className="w-full h-[350px] overflow-hidden">
        <iframe 
        src={extractSrcFromIframe(link)}
        width="100%" 
        height="100%" 
        loading="lazy"/>
    </div>
  )
}
export default Map