import { Outlet } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import ScrollToTop from './ScrollTop'

const BaseLayout = () => {
  return (
    <>
    <Header/>
      <ScrollToTop/>
      <Outlet/>
    <Footer/>
    </>
  )
}

export default BaseLayout