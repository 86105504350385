import Icon from "../ui/icon"
import Image from "../ui/image"
import Home from '@/assets/icon/home.svg'
import { onlyDate, priceMasking } from "@/utils/helpers"
import { Calendar } from "lucide-react"
import { Link } from "react-router-dom"
import { IListing } from "@/types"
import { API_URL } from "@/config"
import { subDays, isAfter } from 'date-fns';

const Advert = ({
    data
}: {
    data: IListing
}) => {

    const oneWeekAgo = subDays(new Date(), 7);
    const isNew = isAfter(new Date(data.createdAt), oneWeekAgo);

  return (
    <div className="w-full h-full p-2 lg:p-3 rounded-xl border flex flex-col justify-between gap-2 lg:gap-3 border-border relative">
        <div className="space-y-2 lg:space-y-3">
        <Link to={`/ilan/${data.seo}`} className="w-full flex aspect-[1.25/1] rounded-xl overflow-hidden">
            <Image src={`${API_URL}/${data.images[0]}`} alt={data.name}/>
        </Link>
        <Link to={`/ilan/${data.seo}`} className="flex w-full">
            <p className="w-full truncate text-sm font-semibold">{data.name}</p>
        </Link>
        <p className="text-xs text-third-dark">{data.neighborhoods} / {data.district}</p>
        <div className="flex items-center gap-2 lg:gap-3 text-xs text-third-dark flex-wrap">
            <div className="flex items-center gap-1">
                <Icon
                    icon={<Home/>}
                    color="#A9B0B9"
                    width="16px"
                />
                <p>{data.type?.name}</p>
            </div>

            {data.infos.map((v, i) => (
                v.typeValue.preview && (
                    <div key={i} className="flex items-center gap-2">
                        <div className="w-4 h-4 shrink-0">
                            <Image
                                src={`${API_URL}/${v.typeValue.icon}`}
                                alt={v.typeValue.name} // Alternatif metin eklemek iyi bir uygulamadır.
                            />
                        </div>
                        <p>
                            {v.typeValue.name.toLowerCase().includes("metrekare") 
                                ? `${v.value} m²` 
                                : v.value}
                        </p>
                    </div>
                )
            ))}
        </div>
        </div>

        <div className="flex items-center gap-2 justify-between flex-wrap">
            <p className="text-sm lg:text-lg font-semibold">{priceMasking(data.price)}</p>
            <p className="flex items-center gap-1 text-xs text-third-dark">
                <Calendar size={16}/> {onlyDate(new Date(data.createdAt))}
                </p>
        </div>

        <div className="flex items-center gap-1 absolute top-2 lg:top-3 left-2 lg:left-3">
            {data.highlights && 
            (<span className="px-2 text-white py-1 rounded-xl text-xs font-semibold bg-first uppercase ">
                Öne Çıkan
            </span>)
            }
            {isNew && (
            <span className="px-2 py-1 rounded-xl text-xs font-semibold bg-red-500 text-white uppercase ">
                YENİ
            </span>
            )}
        </div>
    </div>
  )
}

export default Advert