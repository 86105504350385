/* eslint-disable @typescript-eslint/no-explicit-any */
import SelectComp from "@/components/customUI/SelectComp"
import FilterSide from "./FilterSide"
import Advert from "./Advert"
import { useCallback, useEffect, useState } from "react"
import { List, SearchX, Square } from "lucide-react"
import { cn } from "@/lib/utils"
import SquareAdvert from "@/components/customUI/Advert"
import request from "@/utils/request"
import { CategoriesProps, IListing } from "@/types"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import AdvertLoading from "@/components/customUI/LoadingSkeletons/AdvertLoading"
import VerticalAdvertLoading from "@/components/customUI/LoadingSkeletons/VerticalAdvertLoading"
import { Skeleton } from "@/components/ui/skeleton"
import { PaginationComp } from "@/components/customUI/PaginationComp"
import { useMediaQuery } from "react-responsive";
import ResponsiveFilter from "./ResponsiveFilter"
import { BreadcrumbComponent } from "@/components/customUI/Breadcrumb"
import { Helmet } from "react-helmet-async"
import { company_name, DOMAIN_URL } from "@/config"

export interface District {
    id: number;
    city_id: number;
    name: string;
  }

  export interface Neighborhoods {
    id: number;
    district_id: number;
    name: string;
  }

const CategoryPage = () => {

    const { seo } = useParams<{ seo: string }>();
    const first = useMediaQuery({ query: '(max-width: 1024px)' });
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [listType, setListType] = useState(0)
    const [data, setData] = useState<IListing[]>([])
    const [category, setCategory] = useState<CategoriesProps>()
    const [page, setPage] = useState(searchParams.get('page') ? parseInt(searchParams.get('page') || '1') : 1)
    const [total, setTotal] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [loading, setLoading] = useState(true)
    const [breadcrumbs, setBreadcrumbs] = useState<{link: string, name: string}[]>([])
    useEffect(() => {
      if(first) {
        setListType(1)
      }
    }, [first])
    

    //*Categories
    const [selectedCategory, setSelectedCategory] = useState<string>(searchParams.get('category') || '')
    const [sortValue, setSortValue] = useState(searchParams.get('sort') || '')
    const [dateValue, setDateValue] = useState(searchParams.get('date') || '')
    const [searchText, setSearchText] = useState(searchParams.get('query_text') || '')
    const [price_min, setPriceMin] = useState(searchParams.get('price_min') || '')
    const [price_max, setPriceMax] = useState(searchParams.get('price_max') || '')
    const [query_desc, setQueryDesc] = useState(searchParams.get('query_desc') || '')
    const [district, setDistrict] = useState(searchParams.get('district_id') || '')
    const [neighborhood, setNeighborhood] = useState((searchParams.get('neighborhood_id') || ''))

      const handleGetData = useCallback(async() => {
        if(seo) {
            try {
                setLoading(true);
                const categoryIds = searchParams.get('category') || '';
                const dateValue = searchParams.get('date')
                const searchText = searchParams.get('query_text')
                const query_desc = searchParams.get('query_desc')
                const price_min = searchParams.get('price_min') 
                const price_max = searchParams.get('price_max')
                const district = searchParams.get('district_id')
                const neighborhood = searchParams.get('neighborhood_id')
                const params: {
                    categorySeo?: string;
                    categoryIds?: string[];
                    districtId?: string;
                    neighborhoodId?: string;
                    minPrice?: string;
                    maxPrice?: string;
                    timeFilter?: string;
                    includeDesc?: boolean;
                    searchText?: string;
                    sortBy?: string;
                    sortOrder?: 'ASC' | 'DESC';
                    page?: number;
                    limit?: number;
                } = {};
                if(seo) params.categorySeo = seo
                params.page = page
                if(sortValue) params.sortBy = sortValue === 'newest' || sortValue === 'oldest' ? 'createdAt' : 'price'
                if(sortValue) params.sortOrder = sortValue === 'newest' || sortValue === 'highest' ? 'DESC' : 'ASC'
                params.limit = 20
                if (categoryIds) params.categoryIds = [categoryIds];
                if(price_min) params.minPrice = price_min
                if(price_max) params.maxPrice = price_max
                if(dateValue) params.timeFilter = dateValue
                if(searchText) params.searchText = searchText
                if(query_desc) params.includeDesc = query_desc === 'true' ? true : false
                if(district) params.districtId = district
                if(neighborhood) params.neighborhoodId = neighborhood
    
                const res = await  request({url: '/home/listByCategory', data: params})
                setData(res.data.data)
                setTotalPages(res.data.totalPages)
                setTotal(res.data.total)
                setCategory(res.data.category)
                setBreadcrumbs(res.data.breadcrumbs)
            } catch (error: any) {
                console.log(error)
                navigate('/404')
            } finally {
                setLoading(false)
            }
        }
      }, [navigate, page, searchParams, seo, sortValue])


      useEffect(() => {
        handleGetData()
      }, [handleGetData])

      useEffect(() => {
        if(page > 1) {
            const params = Object.fromEntries([...searchParams]);
    
        params.page = page.toString();
    
        setSearchParams(params);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [page]);

    const [districts, setDistricts] = useState<District[]>([])
    const [neighborhoods, setNeighborhoods] = useState<Neighborhoods[]>([])

    const getDistricts = useCallback(async () => {
            try {
                const response = await request({url: `/address/districts?city_id=${54}`, method: 'get'});
                setDistricts(response.data);
                } catch (err) {
                console.error(err);
                }
        }, []);

    const getNeighborhoods = useCallback(async () => {
    if(district) {
        try {
            const response = await request({url: `/address/neighborhoods?district_id=${district}`, method: 'get'});
            setNeighborhoods(response.data);
            } catch (err) {
            console.error(err);
            }
    }
    }, [district]);

    useEffect(() => {
    getDistricts()
    }, [getDistricts])

    useEffect(() => {
    getNeighborhoods()
    }, [getNeighborhoods])

    const sort = [
        {name: 'Yeniden Eskiye', value: 'newest'},
        {name: 'Eskiden Yeniye', value: 'oldest'},
        {name: 'Artan Fiyat', value: 'lowest'},
        {name: 'Azalan Fiyat', value: 'highest'},
    ]

  return (
    <>
    <Helmet>
      <title>{`${category?.name || 'Emlak'} İlanları | ${company_name}`}</title>
      <meta name="description" content={`${category?.name} kategorisindeki ilanları inceleyin. Sakarya'da hayalinizdeki en iyi ${category?.name} seçenekleri ile hayalinizdeki mülkü bulun. ${category?.name} ilanlarıyla ilgili güncel fırsatları keşfedin!`} />
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={`${category?.name}, ${company_name}, Sakarya emlak, ${category?.name} ilanları, kiralık ${category?.name}, satılık ${category?.name}`} />
      <meta property="og:title" content={`${category?.name} İlanları | ${company_name}`} />
      <meta property="og:description" content={`${category?.name} kategorisindeki ilanları inceleyin. Sakarya'da hayalinizdeki en iyi ${category?.name} seçenekleri ile hayalinizdeki mülkü bulun.`} />
      <meta property="og:url" content={`${DOMAIN_URL}/${category?.seo}`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={company_name} />
    </Helmet>
    <main className="py-5 lg:py-10 space-y-5 container-wrapper">
        {
            !loading ?
            (<h1 className="text-third-dark text-sm lg:text-base"><b>{category?.name}</b> kategorisi {searchText && `"${searchText}"`} {searchText && `araması`} için <b>{total}</b> ilan bulundu.</h1>)
            :
            (
                <Skeleton className="h-5 lg:h-6 rounded-full w-[200px]"/>
            )
        }
        {
            !loading ?
            (
                <BreadcrumbComponent breadcrumbs={breadcrumbs}/>
            )
            :
            (
                <Skeleton className="h-5 rounded-full w-[140px]"/>
            )
        }
        
        <section className="flex gap-5">
            <FilterSide
                district={district}
                setDistrict={setDistrict}
                districts={districts}
                setNeighborhood={setNeighborhood}
                neighborhood={neighborhood}
                neighborhoods={neighborhoods}
                dateValue={dateValue}
                price_max={price_max}
                price_min={price_min}
                query_desc={query_desc}
                searchText={searchText}
                selectedCategory={selectedCategory}
                setDateValue={setDateValue}
                setPriceMax={setPriceMax}
                setPriceMin={setPriceMin}
                setQueryDesc={setQueryDesc}
                setSearchText={setSearchText}
                setSelectedCategory={setSelectedCategory}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                category={category}
            />
            <div className="space-y-3 w-full">
                <div className="flex items-center lg:justify-end pb-3 border-b border-border gap-3">
                    <ResponsiveFilter
                        district={district}
                        setDistrict={setDistrict}
                        districts={districts}
                        setNeighborhood={setNeighborhood}
                        neighborhood={neighborhood}
                        neighborhoods={neighborhoods}
                        dateValue={dateValue}
                        price_max={price_max}
                        price_min={price_min}
                        query_desc={query_desc}
                        searchText={searchText}
                        selectedCategory={selectedCategory}
                        setDateValue={setDateValue}
                        setPriceMax={setPriceMax}
                        setPriceMin={setPriceMin}
                        setQueryDesc={setQueryDesc}
                        setSearchText={setSearchText}
                        setSelectedCategory={setSelectedCategory}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        category={category}
                    />
                    <div className="flex items-center gap-2 max-lg:hidden">
                        <button 
                        onClick={() => setListType(0)}
                        className={cn(listType === 0 ? 'text-first' : 'text-primary', 'duration-300')}>
                            <List/>
                        </button>
                        <button 
                        onClick={() => setListType(1)}
                        className={cn(listType === 1 ? 'text-first' : 'text-primary', 'duration-300')}>
                            <Square/>
                        </button>
                    </div>
                    <div className="lg:w-[200px] w-full">
                    <SelectComp 
                        label="Sırala" 
                        onValueChange={(e) => setSortValue(e)}
                        value={sortValue}
                        data={sort}
                        placeholder="Sırala"/>
                    </div>
                </div>

                {
                !loading ?
                listType === 0 ?
                (
                    data.length > 0 ?
                    <div className="flex flex-col gap-3 w-full lg:min-h-[600px]">
                        {
                            data.map((v, i) => (
                                <Advert
                                    key={i}
                                    data={v}
                                />
                            ))
                        }
                    </div>
                    :
                    <div className="flex min-h-[350px] items-center justify-center flex-col gap-3 w-full h-full text-lg font-semibold text-[#A9B0B9]">
                        <SearchX size={30}/>
                        <p>Sonuç bulunamadı...</p>
                    </div>
                )
                :
                (
                    data.length > 0 ?
                    (
                        <div className="grid lg:min-h-[630px] grid-cols-2 lg:grid-cols-3 gap-3 place-content-start">
                            {
                                data.map((v, i) => (
                                    <SquareAdvert
                                        key={i}
                                        data={v}
                                    />
                                ))
                            }
                        </div>
                    )
                    :
                    <div className="flex min-h-[350px] items-center justify-center flex-col gap-3 w-full h-full text-lg font-semibold text-[#A9B0B9]">
                        <SearchX size={30}/>
                        <p>Sonuç bulunamadı...</p>
                    </div>
                    )
                    :
                    listType === 0 ?
                    (
                        <div className="flex flex-col gap-3 w-full lg:min-h-[600px]">
                            {
                                [...Array(20)].map((_, i) => (
                                <VerticalAdvertLoading key={i}/>
                                ))
                            }
                        </div>
                    )
                    :
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 place-content-start">
                    {
                        [...Array(20)].map((_, i) => (
                        <AdvertLoading key={i}/>
                        ))
                    }
                    </div>
                }

                {
                    totalPages > 0 &&
                    (
                        <PaginationComp
                            setPage={setPage}
                            currentPage={page}
                            totalPages={totalPages}
                        />
                    )
                }
            </div>
        </section>
    </main>
    </>
  )
}

export default CategoryPage