import { onlyDate, priceMasking } from "@/utils/helpers"
import Home from '@/assets/icon/home.svg'
import Icon from "@/components/ui/icon"
import { Calendar, List, LocateIcon, Phone } from "lucide-react"
import { Link } from "react-router-dom"
import { FaWhatsapp } from "react-icons/fa"
import { IListing } from "@/types"
import { API_URL } from "@/config"
import Image from "@/components/ui/image"
import { useSettings } from "@/contexts/SettingsProvider"
import SharePopup from "./SharePopup"

const Aside = ({
    data
}: {
    data: IListing
}) => {

    const {settings} = useSettings()

  return (
    <aside className="col-span-12 lg:col-span-3 space-y-5 p-3 lg:p-5 border-border lg:border rounded-xl h-fit">
        <p className="text-lg font-semibold lg:text-xl">{priceMasking(data.price)}</p>

        <div className="flex items-center gap-3 flex-wrap text-xs font-semibold text-third-dark">
            <div className="flex items-center gap-2">
                <Icon
                    icon={<Home/>}
                    color="#A9B0B9"
                    width="16px"
                />
                <p>{data.type?.name}</p>
            </div>
            {data.infos.map((v, i) => (
                v.typeValue.preview && (
                    <div key={i} className="flex items-center gap-2">
                        <div className="w-4 h-4 shrink-0">
                            <Image
                                src={`${API_URL}/${v.typeValue.icon}`}
                                alt={v.typeValue.name} // Alternatif metin eklemek iyi bir uygulamadır.
                            />
                        </div>
                        <p>
                            {v.typeValue.name.toLowerCase().includes("metrekare") 
                                ? `${v.value} m²` 
                                : v.value}
                        </p>
                    </div>
                )
            ))}
        </div>

        <p className="text-xs font-semibold text-third-dark">{data.neighborhoods} / {data.district}</p>

        <div className="flex items-center gap-5 flex-wrap text-xs text-third-dark">
            <p className="flex items-center gap-1"><List size={16}/> <span>İlan No: <b>{data.id}</b></span></p>
            <p className="flex items-center gap-1"><Calendar size={16}/> <b>{onlyDate(new Date(data.createdAt))}</b></p>
        </div>

        <div className="space-y-2">
            <Link target="_blank" to={data.location} 
            className="w-full h-10 text-white bg-blue-400 text-primary text-sm font-semibold flex items-center justify-center gap-3 rounded-xl hover:bg-opacity-80 duration-300">
                <LocateIcon size={24}/>
                <span>Konuma Git</span>
            </Link>
            <Link to={`tel:${settings?.phone}`} 
            className="w-full h-10 bg-first text-white text-sm font-semibold flex items-center justify-center gap-3 rounded-xl hover:bg-opacity-80 duration-300">
                <Phone size={24}/>
                <span>Ara</span>
            </Link>
            <Link target="_blank" to={`https://wa.me/${settings?.whatsApp}`}
            className="w-full h-10 bg-green-500 text-white text-sm font-semibold flex items-center justify-center gap-3 rounded-xl hover:bg-opacity-80 duration-300">
                <FaWhatsapp size={24}/>
                <span>WhatsApp</span>
            </Link>
        </div>

        <div className="space-y-3 text-sm font-semibold">
            <SharePopup data={data}/>
            {/* <button className="flex items-center w-full border-t pt-3 justify-between text-primary hover:text-first duration-300">
                <p>İlanı Kaydet</p>
                <Bookmark size={24}/>
            </button> */}
        </div>
    </aside>
  )
}

export default Aside