import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTrigger,
  } from "@/components/ui/dialog"
import { IListing } from "@/types"
import { share } from "@/utils/helpers"
import { Facebook, Share2Icon, Twitter } from "lucide-react"
import { FaWhatsapp } from "react-icons/fa"

const SharePopup = ({
    data
}: {
    data: IListing
}) => {
  return (
    <Dialog>
        <DialogTrigger asChild>
                <button className="flex w-full items-center justify-between text-primary hover:text-first duration-300">
                    <p>İlanı Paylaş</p>
                    <Share2Icon size={24}/>
                </button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>İlanı Paylaş</DialogHeader>
            <div className="flex items-center justify-between gap-5 flex-wrap px-5 pt-5">
                <button 
                className="flex flex-col gap-3 items-center text-sm font-semibold text-third-dark hover:text-first duration-300"
                onClick={() => share({_title: data.name, platform: 'whatsapp', previewImage: data.images[0]})}>
                    <FaWhatsapp size={24}/>
                    <p>WhatsApp</p>
                </button>
                <button 
                className="flex flex-col gap-3 items-center text-sm font-semibold text-third-dark hover:text-first duration-300"
                onClick={() => share({_title: data.name, platform: 'facebook', previewImage: data.images[0]})}>
                    <Facebook size={24}/>
                    <p>Facebook</p>
                </button>
                <button 
                className="flex flex-col gap-3 items-center text-sm font-semibold text-third-dark hover:text-first duration-300"
                onClick={() => share({_title: data.name, platform: 'twitter', previewImage: data.images[0]})}>
                    <Twitter size={24}/>
                    <p>Twitter</p>
                </button>
            </div>
        </DialogContent>
    </Dialog>
  )
}

export default SharePopup