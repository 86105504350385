import { Button } from "@/components/ui/button"
import Image from "@/components/ui/image"
import { Search } from "lucide-react"
import { useState } from "react"
import Bg from '@/assets/images/gundogdu-emlak-sakarya-emlak.jpg'
import SearchModal from "./SearchModal"
const Hero = () => {
    const [open, setOpen] = useState(false)

  return (
    <section 
    className="w-full aspect-[1920/1200] lg:aspect-[1920/480] relative !object-center lg:object-left flex items-center justify-center">
        <Image src={Bg}/>
        <div className="absolute inset-0 container-wrapper flex max-lg:bg-black/40">
            <div className="lg:w-[50%] flex justify-center flex-col gap-5 px-5">
                <h1 className="text-xl font-semibold max-lg:text-lg max-lg:text-center text-white">
                Günsa Gayrimenkul: Sakarya'da Hayalinizdeki Emlak Fırsatları
                </h1>
                <p className="text-sm text-white max-lg:line-clamp-3 max-lg:text-center max-lg:text-xs">
                Sakarya'nın en güvenilir emlak firması Günsa Gayrimenkul ile hayalinizdeki ev veya yatırım fırsatını bulun. Kiralık ve satılık daireler, arsalar ve ticari gayrimenkuller için profesyonel danışmanlık hizmeti alarak en iyi yatırım kararlarınızı verin. Geniş portföyümüzle Sakarya'daki en iyi fırsatları keşfedin!
                </p>
                <div className="flex max-lg:justify-center">
                <Button 
                    onClick={() => setOpen(!open)}
                    className="gap-3 w-fit shadow-lg" variant={'first'}><Search size={20}/> İlan Ara
                </Button>
                </div>
            </div>
        </div>

        <SearchModal
            onClose={() => setOpen(false)}
            open={open}
        />
    </section>
  )
}

export default Hero