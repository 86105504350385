import { Link } from "react-router-dom"
import Icon from "../ui/icon"
import Logo from '@/assets/icon/logo.svg'
import { useCategories } from "@/contexts/CategoriesProvider"
import Image from "../ui/image"
import { API_URL } from "@/config"
import { Skeleton } from "../ui/skeleton"
import NavMenu from "./NavMenu"
import { Phone } from "lucide-react"
import { useSettings } from "@/contexts/SettingsProvider"
const Header = () => {

    const {categories, loading} = useCategories()
    const {settings} = useSettings()

  return (
    <header className="flex items-center sticky top-0 z-50 bg-white justify-between gap-10 border-b border-border px-3 lg:px-10 max-lg:py-3">
        <NavMenu/>
        <Link to={'/'}>
            <Icon width="120px" height="" icon={<Logo/>}/>
        </Link>

        <nav className="w-full max-lg:hidden flex relative items-center justify-center">
            <ul className="flex items-center text-sm font-semibold gap-5">
                {
                    !loading ?
                    <>
                    {
                        categories.map((v, i) => (
                            <li key={i} className="hover:text-first group duration-300 cursor-pointer py-5">
                                <Link to={`/${v.seo}`}>{v.name}</Link>
                                <div 
                                className="absolute hidden group-hover:flex duration-300 animate-dropTop left-0 top-full z-50 h-fit 
                                p-5 w-full bg-white rounded-b-xl border border-border shadow-lg">
                                    <ul className="container-wrapper grid grid-cols-4 place-content-start text-primary gap-5">
                                        {
                                            v.home_sub?.map((h, i) => (
                                                <li key={i}>
                                                    <Link to={`/${h.seo}`} className="flex items-center gap-3">
                                                        {h.image &&
                                                        (<Image
                                                            src={`${API_URL}/${h.image}`}
                                                            width="24px"
                                                        />)
                                                        }
                                                        <span>{h.name}</span>
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </li>
                        ))
                    }
                    <Link className="hover:text-first duration-300" to={'/haberler'}>
                        Emlak Haberleri
                    </Link>
                    <Link to={'/hakkimizda'} className="hover:text-first duration-300">
                        Hakkımızda
                    </Link>
                    <Link to={'/iletisim'} className="hover:text-first duration-300">
                        İletişim
                    </Link>
                    </>
                    :
                    [...Array(5)].map((_, i) => (
                        <Skeleton key={i} className="w-20 h-5 rounded-full my-5"/>
                    ))
                }
            </ul>
        </nav>

        <div className="flex justify-end shrink-0">
        {/* <Button 
        onClick={() => loginModal.onOpen()}
        variant={'first'} className="gap-3"> <User2/> <p className="max-lg:hidden">Giriş Yap</p></Button> */}
        <Link to={`tel:${settings?.phone}`} className="h-10 px-4 text-white flex items-center gap-3 text-sm font-medium duration-300 rounded-xl py-2 text-primary bg-first hover:text-first hover:bg-primary">
            <Phone/> <p className="max-lg:hidden">Ara</p>
        </Link>
        </div>
    </header>
  )
}

export default Header