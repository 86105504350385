import moment from 'moment';

  export function calculateDiscountPercentage(price: number | null, special: number | null): number | null {
    if (!price || !special) {
        return null;
    }

    const discount = ((price - special) / price) * 100;
    return Math.round(discount); // İndirimi tam sayıya yuvarlar
}

export function priceMasking(price: number): string {
  return price.toLocaleString('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
  });
}

export function formatDateWithDayInTurkish(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long', 
    year: 'numeric', 
    month: 'long',   
    day: 'numeric'    
  };
  
  return new Intl.DateTimeFormat('tr-TR', options).format(date);
}

export const onlyDate = (date: Date | string | number) => {
  const trDateFormat = 'DD.MM.YYYY';
  moment.locale('tr'); 
  return moment(date).format(trDateFormat);
}

export const dateFormater = (date: Date) => {
  const turkishDateFormat = 'DD.MM.YYYY HH:mm';
  moment.locale('tr'); 
  return moment(date).format(turkishDateFormat);
}

export function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',   
    year: 'numeric',   
    month: 'long',   
    day: 'numeric',   
    hour: '2-digit',   
    minute: '2-digit'  
  };
  
  const formattedDate = new Intl.DateTimeFormat('tr-TR', options).format(date);
  
  return formattedDate.replace(',', '');
}

export function extractSrcFromIframe(iframeString: string) {
  const srcMatch = iframeString.match(/src="([^"]*)"/);
  return srcMatch ? srcMatch[1] : undefined;
}

interface Props {
  _title?: string | null;
  pathname?: string | null;
  platform?: string | null;
  previewImage?: string | null; // Yeni eklenen özellik: önizleme resmi URL'si
}

export function share({
  _title,
  platform,
  previewImage
}: Props) {
  const pageUrl = window.location.href;
  let shareMessage = `${_title} - ${pageUrl}`;

  let platformUrl = '';

  if (platform === 'twitter') {
    platformUrl = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(shareMessage);
  } else if (platform === 'facebook') {
    platformUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(pageUrl);
  } else if (platform === 'whatsapp') {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      platformUrl = `whatsapp://send?text=${encodeURIComponent(shareMessage)}`;
    } else {
      platformUrl = `https://web.whatsapp.com/send?text=${encodeURIComponent(shareMessage)}`;
    }
  }

  // Önizleme resmi eklenmişse
  if (previewImage) {
    shareMessage += `&image=${encodeURIComponent(previewImage)}`;
  }

  if (platformUrl) {
    window.open(platformUrl);
  }
}

function etiketleriTemizle(desc: string) {
  return desc?.replace(/<[^>]*>/g, "");
}


export function seoDesc(desc: string) {
  const temizMetin = etiketleriTemizle(desc);
  return temizMetin?.slice(0, 157) + '...';
}


