/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreadcrumbComponent } from "@/components/customUI/Breadcrumb"
import Images from "./Images"
import Aside from "./Aside"
import Tabs from "./Tabs"
import Advert from "@/components/customUI/Advert"
import Heading from "@/components/customUI/Heading"
import { useNavigate, useParams } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"
import { IListing } from "@/types"
import request from "@/utils/request"
import LoadingScreen from "@/components/customUI/LoadingScreen"
import Slider from "react-slick"
import { Helmet } from "react-helmet-async"
import { API_URL, company_name, DOMAIN_URL } from "@/config"
const AdvertPage = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        className: 'slider-size animate-fade',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 360,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    const { seo } = useParams<{ seo: string }>();
    const navigate = useNavigate()
    const [breadcrumbs, setBreadcrumbs] = useState<{link: string, name: string}[]>([])
    const [data, setData] = useState<IListing>()
    const [loading, setLoading] = useState(true)

    const handleGetData = useCallback(async() => {
        try {
            const res = await request({url: `/home/listBySeo?seo=${seo}`, method: 'get'})
            setData(res.data.listing)
            setBreadcrumbs(res.data.breadcrumbs)
        } catch (err: any) {
            if (err.response && err.response.status === 404) {
                navigate('/404');
                } else {
                throw new Error(err);
                }
        } finally {
            setLoading(false)
        }
    }, [navigate, seo])

    useEffect(() => {
        handleGetData()
    }, [handleGetData])

    const [similars, setSimilars] = useState<IListing[]>([])

    const handleGetSimilars = useCallback(async() => {
        try {
            if(data) {
                const res = await request({url: `/home/similars?typeId=${data.type?.id}&districtId=${data.district_id}&listingId=${data.id}`, method: 'get'}) 
                setSimilars(res.data)
            }
        } catch (error:any) {
            throw new Error(error)
        }
    }, [data])

    useEffect(() => {
        handleGetSimilars()
    }, [handleGetSimilars])
    
    
    if(!data && loading) {
        return <LoadingScreen/>
    }

  return (
    <>
    <Helmet>
      <title>{`${data?.name || 'İlan Detayı'} | ${company_name}`}</title>
      <meta name="description" content={data?.short_desc} />
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={`${data?.name}, emlak, ilan, Sakarya, ${company_name}`} />
      <meta property="og:title" content={`${data?.name || 'İlan Detayı'} | ${company_name}`} />
      <meta property="og:description" content={data?.short_desc} />
      <meta property="og:url" content={`${DOMAIN_URL}/ilan/${data?.seo}`} />
      <meta property="og:image" content={`${API_URL}/${data?.images[0]}`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={company_name} />
    </Helmet>
    <main className="space-y-5 container-wrapper py-5 lg:py-5">
        <BreadcrumbComponent breadcrumbs={breadcrumbs}/>
        
        <div className="grid grid-cols-12 gap-5">
            <section className="col-span-12 lg:col-span-9 space-y-5">
                <h1 className="text-sm font-semibold lg:text-lg line-clamp-1">{data?.name}</h1>
                <Images images={data!.images}/>
            </section>

            <Aside data={data!}/>

            <Tabs data={data!}/>

            {
                similars.length > 0 &&
                (
                    <div className="col-span-12 lg:col-span-9 space-y-3 mb-5">
                        <Heading title="Benzer İlanlar"/>
                        {similars.length > 3 ?
                            (
                                <div className="w-full pb-10">
                                    <Slider {...settings}>
                                    {
                                        similars?.map((v, i) => (
                                        <div key={i} className="px-2 w-full">
                                            <Advert
                                                data={v}
                                            />
                                        </div>
                                        ))
                                    }
                                    </Slider>
                                </div>
                            )
                            :
                            (
                                <div className="grid grid-cols-2 lg:grid-cols-3 gap-3">
                                {
                                    similars.map((v, i) => (
                                        <Advert data={v} key={i}/>
                                    ))
                                }
                            </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    </main>
    </>
  )
}

export default AdvertPage