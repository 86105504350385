import Icon404 from '@/assets/icon/404.svg'
import Icon from '../ui/icon'
import { Link } from 'react-router-dom'
const NotFoundPage = () => {
  return (
    <div className="fixed inset-0 bg-first z-50 px-5 flex-col gap-5 flex items-center justify-center overflow-hidden">
        <b className='text-lg text-center'>Üzgünüz, aradığınız sayfa bulunamadı. </b>
        <Icon
            icon={<Icon404/>}
            width='250px'
            height=''
        />

        <p className='text-lg font-semibold text-center'> Bu sayfa kaldırılmış, taşınmış ya da hiç mevcut olmamış olabilir. </p>

        <Link to={'/'} className='px-5 h-10 rounded-md flex items-center justify-center bg-primary text-first hover:bg-opacity-50 text-sm font-medium duration-300'>
            Ana sayfaya dön
        </Link>
    </div>
  )
}

export default NotFoundPage