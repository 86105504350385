import Icon from "@/components/ui/icon"
import Image from "@/components/ui/image"
import Home from '@/assets/icon/home.svg'
import { Calendar } from "lucide-react"
import { onlyDate, priceMasking } from "@/utils/helpers"
import { Link } from "react-router-dom"
import { IListing } from "@/types"
import { API_URL } from "@/config"
import { isAfter, subDays } from "date-fns"

const Advert = ({
    data
}: {
    data : IListing
}) => {

    const oneWeekAgo = subDays(new Date(), 7);
    const isNew = isAfter(new Date(data.createdAt), oneWeekAgo);

  return (
    <div className="w-full border border-border rounded-xl overflow-hidden flex">
        <Link to={`/ilan/${data.seo}`} className="w-[240px] flex shrink-0 aspect-[240/160] relative">
            <Image src={`${API_URL}/${data.images[0]}`} alt={data.name}/>
            <div className="flex items-center gap-1 absolute top-0 left-0">
                {data.highlights &&
                (<span className="px-2 text-white py-1 rounded-xl text-xs font-semibold bg-first uppercase ">
                    Öne Çıkan
                </span>)
                }

                {isNew && 
                (<span className="px-2 py-1 rounded-xl text-xs font-semibold bg-red-500 text-white uppercase ">
                    YENİ
                </span>
                )}
            </div>
        </Link>
        <div className="p-5 space-y-3 w-full">
            <div className="flex items-center w-full justify-between gap-5">
                <p className="w-full truncate text-sm font-semibold">{data.name}</p>
                {/* <button className="shrink-0 hover:text-first text-primary duration-300">
                    <Bookmark/>
                </button> */}
            </div>
            <p className="text-xs font-semibold text-third-dark">{data.neighborhoods} / {data.district}</p>
            <div className="flex font-semibold items-center gap-2 lg:gap-3 text-xs text-third-dark flex-wrap">
                <div className="flex items-center gap-1">
                    <Icon
                        icon={<Home/>}
                        color="#A9B0B9"
                        width="16px"
                    />
                    <p>{data.type?.name}</p>
                </div>
                {data.infos.map((v, i) => (
                v.typeValue.preview && (
                    <div key={i} className="flex items-center gap-2">
                        <div className="w-4 h-4 shrink-0">
                            <Image
                                src={`${API_URL}/${v.typeValue.icon}`}
                                alt={v.typeValue.name} // Alternatif metin eklemek iyi bir uygulamadır.
                            />
                        </div>
                        <p>
                            {v.typeValue.name.toLowerCase().includes("metrekare") 
                                ? `${v.value} m²` 
                                : v.value}
                        </p>
                    </div>
                )
            ))}
                <p className="flex items-center gap-1"><Calendar size={16}/> {onlyDate(new Date(data.createdAt))}</p>
            </div>
            <p className="text-sm lg:text-xl font-semibold">{priceMasking(data.price)}</p>
        </div>
    </div>
  )
}

export default Advert