/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';
import { ISettings } from '@/types';
import request from '@/utils/request';

interface SettingsContextType {
    settings: ISettings | undefined;
    loading: boolean;
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [settings, setSettings] = useState<ISettings>();
  const [loading, setLoading] = useState(true);
  

  const handleGetSettings = useCallback(async() => {
    try {
      setLoading(true);
        const res = await request({url: `/settings/get`, method: 'get'})
        setSettings(res.data.settings)
    } catch (error: any) {
        throw new Error(error)
    } finally {
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    handleGetSettings()
  }, [handleGetSettings])
  

  return (
    <SettingsContext.Provider value={{ settings, loading }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = (): SettingsContextType => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('user must be used within a UserProvider');
  }
  return context;
};