/* eslint-disable @typescript-eslint/no-explicit-any */
import Advert from "@/components/customUI/Advert"
import Heading from "@/components/customUI/Heading"
import { Button } from "@/components/ui/button"
import { CategoriesProps, IListing } from "@/types"
import Slider from "react-slick"
import { settings } from "./slider-settings"
import AdvertLoading from "@/components/customUI/LoadingSkeletons/AdvertLoading"
import { useCallback, useEffect, useState } from "react"
import request from "@/utils/request"

const CategoryComp = ({
  category,
}: {
    category: CategoriesProps
}) => {
  const handleLocate = () => {
    window.location.href = `/${category.seo}`
  }

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<IListing[]>([])

  const handleGetData = useCallback(async() => {
    try {
        const res = await  request({url: '/home/listByCategory', data: {categorySeo: category.seo}})
        setData(res.data.data)
    } catch (error: any) {
        console.log(error)
    } finally {
        setLoading(false)
    }
  }, [category.seo])

  useEffect(() => {
    handleGetData()
  }, [handleGetData])
  

  return (
    <section className="container-wrapper space-y-3 lg:space-y-5 max-lg:px-2">
        <div className="flex items-center justify-between">
            <Heading title={category.name}/>
            <Button onClick={handleLocate} variant={'first'}>Tümünü Gör</Button>
        </div>

        {
          !loading ?
          data.length > 3 ?
          <div className="w-full pb-10">
            <Slider {...settings}>
              {
                data?.map((v, i) => (
                  <div key={i} className="px-2 w-full">
                    <Advert
                        data={v}
                    />
                  </div>
                ))
              }
            </Slider>
          </div>
          :
          <div className="w-full pb-10 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 grid gap-2 lg:gap-4">
              {
                data?.map((v, i) => (
                  <div key={i} className="px-2 w-full">
                    <Advert
                        data={v}
                    />
                  </div>
                ))
              }
          </div>
          :
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
            {
              [...Array(4)].map((_, i) => (
                <AdvertLoading key={i}/>
              ))
            }
          </div>
        }
    </section>
  )
}

export default CategoryComp