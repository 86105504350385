import { IListing } from "@/types"
import { onlyDate } from "@/utils/helpers"

const Info = ({
    data
}: {
    data: IListing
}) => {

  return (
    <div className="p-5 text-sm text-third-dark min-h-[350px] max-h-fit place-content-start overflow-y-auto grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-5">
        <div className="flex items-center py-2 justify-between border-b border-border text-xs">
            <b>İlan No</b>
            <b className="text-first">{data.id}</b>
        </div>
        <div className="flex items-center py-2 justify-between border-b border-border text-xs">
            <b>İlan Tarihi</b>
            <p className="">{onlyDate(new Date(data.createdAt))}</p>
        </div>
        {
            data.infos.map((v, i) => (
                v.value && (
                    <div 
                        key={i} 
                        className="flex items-center py-2 justify-between border-b border-border text-xs">
                        <b>{v.typeValue.name}</b>
                        <p className="">
                            {v.typeValue.name.toLowerCase().includes("metrekare") 
                                ? `${v.value} m²` 
                                : v.value}
                        </p>
                    </div>
                )
            ))
        }
    </div>
  )
}

export default Info