
const Description = ({
  desc
}: {
  desc: string
}) => {
  return (
    <div className="p-5 text-sm min-h-[350px] text-third-dark max-h-[550px] overflow-y-auto">
        <p
          className="w-full"
          dangerouslySetInnerHTML={{__html:desc}}
        />
    </div>
  )
}

export default Description